import React from 'react';
import './FreeTrialPage.css';
import { useNavigate } from 'react-router-dom';
import vailsresultblur from '../../../../assets/images/VaisBlurtable_up.png';
import abmlal from '../../../../assets/images/abmlal_up.png';
export default function FreeTrialPage({ type }) {
    const navigate = useNavigate();

    return (
        <div className="freepageContentBlock freeBlockBG">
            <div className="">
                <div className="freecontentWrapper FindProspects">
                    <div
                        className="bgTable"
                        style={{ backgroundImage: `url(${type === 'ICP' ? vailsresultblur : abmlal})` }} // Correctly format the URL
                    >
                        <div className='FreeBlockText'>
                        <div className='d-flex justify-content-center align-items-center flex-column'>
                            <div className="textOverlay">
                                <h1>To access this page, you will need to upgrade your plan. Please consider doing so to enjoy the additional features.</h1>
                            </div>
                            <button className="ctaButton" onClick={() => navigate("/subscription-plans")}>
                                Get Started
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
