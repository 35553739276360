import React, { useEffect, useCallback, useRef, useState } from "react";
import "./AddProfilePicture.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfileImage } from "../../../../context/actions/User";

import Webcam from "react-webcam";
import { getCroppedImg } from "../../../../utils/Validations/ComonFunctions";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";

const AddProfilePicture = () => {
  const imgRef = useRef(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const userData = useSelector((state) => state?.user);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false); // Track capturing state
  const [isCropping, setIsCropping] = useState(false); // Track cropping state
  const [error, setError] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const webcamRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cameraAvailable, setCameraAvailable] = useState(false); // Track camera availability

  // const [completedCrop, setCompletedCrop] = useState(null);
  const handleUploadButtonClick = (e) => {
    e.preventDefault();
    // Trigger click event on file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setSelectedImage(imageSrc);
    setIsCapturing(false);
    setIsCropping(true);
  };

  useEffect(() => {
    setIsCapturing(false);
    setIsCropping(false);
    setSelectedImage(false);
    // Check if camera is available
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        setCameraAvailable(true);
      })
      .catch(() => {
        setCameraAvailable(false);
      });
  }, []);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const handleCropImage = async (e) => {
    e.preventDefault();
    try {
      if (selectedImage) {
        const croppedBlob = await getCroppedImg(selectedImage, crop);
        const croppedFile = new File([croppedBlob], "cropped_image.jpg", {
          type: "image/jpeg",
        });
        setCroppedImage(croppedFile);
      } else {
        toast("No image selected for cropping.", { autoClose: 1000 });
      }
    } catch (error) {
      toast("Error cropping image:", { autoClose: 1000 });
    }
  };

  const handleClose = () => {
    setIsCapturing(false);
    setIsCropping(false);
    setSelectedImage(false);
    setIsUploaded(false)
    setCroppedImage(null)
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        setCameraAvailable(true);
      })
      .catch(() => {
        setCameraAvailable(false);
      });
      setError(null)
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (!file) {
      setError("No file selected.");
      return;
    }

    // Check for valid image file types (JPG, PNG, JPEG)
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      setError("Invalid file type. Please upload a JPG, JPEG, or PNG image.");
      setSelectedImage(null); // Clear any previous preview
      return;
    }

    // Check for folder selection (if multiple files or directories are selected)
    if (event.target.files.length > 1) {
      setError("Please select only one image file.");
      setSelectedImage(null); // Clear any previous preview
      return;
    }

    // Clear error if the image is valid
    setError(null);

    // Update state with selected image (preview)
    setSelectedImage(URL.createObjectURL(file));

    // Prepare FormData to send to the backend
    const formData = new FormData();
    formData.append("user", userId);
    formData.append("profile_image", file);

    // Dispatch action to update user profile image
    dispatch(
      updateUserProfileImage(formData, token, (responseData) => {
        // After successful upload
        setIsUploaded(true);
        // Optionally reload the page to show updated profile image
        window.location.reload(true);
      })
    );
  };

  const dataURItoFile = (dataURI, filename) => {
    const arr = dataURI.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleSaveImage = async (e) => {
    e.preventDefault();

    if (croppedImage == null) {
      const file = dataURItoFile(selectedImage, "profile_image.jpg");

      // Prepare FormData to be sent to the backend
      const formData = new FormData();
      formData.append("user", userId);
      formData.append("profile_image", file);

      try {
        // Call API to update user profile image
        const responseData = await dispatch(
          updateUserProfileImage(formData, token)
        );
        setIsUploaded(true);
        window.location.reload(true);
      } catch (error) {
        // console.error("Error updating profile image:", error);
        toast("Error updating profile image:");
      }
    } else {
      // Prepare FormData to be sent to the backend
      const formData = new FormData();
      formData.append("user", userId);
      formData.append("profile_image", croppedImage);

      try {
        // Call API to update user profile image
        const responseData = await dispatch(
          updateUserProfileImage(formData, token)
        );
        setIsUploaded(true);
        window.location.reload(true);
      } catch (error) {
        // console.error("Error updating profile image:", error);
        toast("Error updating profile image:");
      }
    }
  };

  useEffect(() => {
    if (isUploaded) {
      setTimeout(() => {
        setIsUploaded(false);
        setSelectedImage(null);
        navigate("/settings");
      }, 2000); // 2000 milliseconds delay before redirecting
    }
  }, [isUploaded, navigate]);

  return (
    <div
      className={`modal AddProfilePictureModal fade`}
      id="AddProfilePictureModal"
      tabIndex="-1"
      aria-labelledby="AddProfilePictureModal"
      aria-hidden={!isUploaded}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <span
            className="closeModal"
            data-bs-dismiss="modal"
            onClick={()=>handleClose()}
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M7.00047 8.0543L1.92737 13.1274C1.78892 13.2658 1.61489 13.3367 1.40527 13.3399C1.19567 13.3431 1.01844 13.2723 0.873575 13.1274C0.728691 12.9825 0.65625 12.8069 0.65625 12.6005C0.65625 12.3941 0.728691 12.2184 0.873575 12.0736L5.94665 7.00047L0.873575 1.92737C0.735108 1.78892 0.664275 1.61489 0.661075 1.40527C0.657858 1.19567 0.728691 1.01844 0.873575 0.873575C1.01844 0.728691 1.19407 0.65625 1.40047 0.65625C1.60687 0.65625 1.78251 0.728691 1.92737 0.873575L7.00047 5.94665L12.0736 0.873575C12.212 0.735108 12.3861 0.664275 12.5957 0.661075C12.8053 0.657858 12.9825 0.728691 13.1274 0.873575C13.2723 1.01844 13.3447 1.19407 13.3447 1.40047C13.3447 1.60687 13.2723 1.78251 13.1274 1.92737L8.0543 7.00047L13.1274 12.0736C13.2658 12.212 13.3367 12.3861 13.3399 12.5957C13.3431 12.8053 13.2723 12.9825 13.1274 13.1274C12.9825 13.2723 12.8069 13.3447 12.6005 13.3447C12.3941 13.3447 12.2184 13.2723 12.0736 13.1274L7.00047 8.0543Z"
                fill="black"
              />
            </svg>
          </span>
          <div className="modal-body text-center">
            <h2>Add Profile Picture</h2>
            {isCapturing ? (
              cameraAvailable ? (
                <>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    style={{ width: "190px", maxHeight: "calc(100vh - 300px)" }}
                  />
                  <button disabled={!cameraAvailable} onClick={captureImage} className="m-auto">
                    Capture
                  </button>
                </>
              ) : (
                <h2>Please Attach Camera To Click A Photo !</h2>
              )
            ) : isCropping ? (
              <div style={{ position: "relative", width: "100%" }}>
                <ReactCrop
                  // src={upImg}
                  src={selectedImage}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  // onComplete={(c) => setCompletedCrop(c)}
                />
              </div>
            ) : (
              <div className="uploadDocuments">
                <input
                  ref={fileInputRef}
                  className="uploadDocInput"
                  id="userImgUploadInput"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  style={{ display: "none" }} // Hide the input element
                  onChange={handleFileChange}
                />
                <div className="uploadDocContent">
                  {
                    selectedImage || croppedImage ? (
                      <img
                        src={selectedImage || croppedImage}
                        // alt="Selected"
                        className="selectedImage"
                        // style={{ marginLeft: "35px" }}
                      />
                    ) : null 
                  }
                </div>
              </div>
            )}
            {!isCropping &&
              !isCapturing &&
              !selectedImage && ( // If neither capturing nor cropping mode is on, show buttons
                <div className="buttonGroup takePic">
                  <button
                    className="btn btnPrimaryOutline w-100"
                    onClick={() => setIsCapturing(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <path
                        d="M9.99868 15.1234C10.9549 15.1234 11.7655 14.7909 12.4306 14.1259C13.0956 13.4608 13.4281 12.6502 13.4281 11.694C13.4281 10.7378 13.0956 9.92717 12.4306 9.26212C11.7655 8.59706 10.9549 8.26453 9.99868 8.26453C9.04248 8.26453 8.23186 8.59706 7.5668 9.26212C6.90175 9.92717 6.56922 10.7378 6.56922 11.694C6.56922 12.6502 6.90175 13.4608 7.5668 14.1259C8.23186 14.7909 9.04248 15.1234 9.99868 15.1234ZM9.99868 13.8735C9.38329 13.8735 8.86619 13.6641 8.44739 13.2453C8.02858 12.8265 7.81918 12.3094 7.81918 11.694C7.81918 11.0786 8.02858 10.5615 8.44739 10.1427C8.86619 9.72389 9.38329 9.51449 9.99868 9.51449C10.6141 9.51449 11.1312 9.72389 11.55 10.1427C11.9688 10.5615 12.1782 11.0786 12.1782 11.694C12.1782 12.3094 11.9688 12.8265 11.55 13.2453C11.1312 13.6641 10.6141 13.8735 9.99868 13.8735ZM3.58845 17.944C3.1675 17.944 2.8112 17.7981 2.51953 17.5065C2.22786 17.2148 2.08203 16.8585 2.08203 16.4376V6.95043C2.08203 6.52948 2.22786 6.17318 2.51953 5.88151C2.8112 5.58984 3.1675 5.44401 3.58845 5.44401H6.1333L7.22305 4.26293C7.3598 4.11229 7.52514 3.9937 7.71905 3.90716C7.91296 3.82061 8.11727 3.77734 8.33201 3.77734H11.6653C11.8801 3.77734 12.0844 3.82061 12.2783 3.90716C12.4722 3.9937 12.6376 4.11229 12.7743 4.26293L13.8641 5.44401H16.4089C16.8299 5.44401 17.1862 5.58984 17.4778 5.88151C17.7695 6.17318 17.9153 6.52948 17.9153 6.95043V16.4376C17.9153 16.8585 17.7695 17.2148 17.4778 17.5065C17.1862 17.7981 16.8299 17.944 16.4089 17.944H3.58845ZM3.58845 16.694H16.4089C16.4837 16.694 16.5451 16.6699 16.5932 16.6219C16.6413 16.5738 16.6653 16.5123 16.6653 16.4376V6.95043C16.6653 6.87564 16.6413 6.8142 16.5932 6.76611C16.5451 6.71803 16.4837 6.69399 16.4089 6.69399H13.3064L11.7775 5.02732H8.21982L6.69099 6.69399H3.58845C3.51366 6.69399 3.45222 6.71803 3.40414 6.76611C3.35605 6.8142 3.33201 6.87564 3.33201 6.95043V16.4376C3.33201 16.5123 3.35605 16.5738 3.40414 16.6219C3.45222 16.6699 3.51366 16.694 3.58845 16.694Z"
                        fill="#E55426"
                      />
                    </svg>{" "}
                    Take a picture
                  </button>
                  <div className="getStarted">
                    <button
                      className="btn w-100"
                      onClick={handleUploadButtonClick}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M1.77732 17.7187C1.59998 17.7187 1.45148 17.6588 1.33182 17.5392C1.21217 17.4195 1.15234 17.271 1.15234 17.0937C1.15234 16.9163 1.21216 16.7678 1.3318 16.6482C1.45145 16.5285 1.59995 16.4687 1.7773 16.4687H18.2196C18.3969 16.4687 18.5454 16.5285 18.6651 16.6482C18.7847 16.7678 18.8446 16.9163 18.8446 17.0937C18.8446 17.271 18.7847 17.4195 18.6651 17.5392C18.5454 17.6588 18.3969 17.7187 18.2196 17.7187H1.77732ZM3.58824 15.6354C3.16729 15.6354 2.81099 15.4895 2.51932 15.1979C2.22766 14.9062 2.08182 14.5499 2.08182 14.129V5.47515C2.08182 5.05422 2.22766 4.69792 2.51932 4.40625C2.81099 4.11458 3.16729 3.96875 3.58824 3.96875H16.4087C16.8296 3.96875 17.1859 4.11458 17.4776 4.40625C17.7693 4.69792 17.9151 5.05422 17.9151 5.47515V14.129C17.9151 14.5499 17.7693 14.9062 17.4776 15.1979C17.1859 15.4895 16.8296 15.6354 16.4087 15.6354H3.58824ZM3.58824 14.3854H16.4087C16.4728 14.3854 16.5316 14.3587 16.585 14.3053C16.6384 14.2518 16.6651 14.1931 16.6651 14.129V5.47515C16.6651 5.41103 16.6384 5.35227 16.585 5.29885C16.5316 5.24542 16.4728 5.21871 16.4087 5.21871H3.58824C3.52413 5.21871 3.46536 5.24542 3.41193 5.29885C3.35851 5.35227 3.3318 5.41103 3.3318 5.47515V14.129C3.3318 14.1931 3.35851 14.2518 3.41193 14.3053C3.46536 14.3587 3.52413 14.3854 3.58824 14.3854Z"
                          fill="white"
                        />
                      </svg>{" "}
                      Upload from device
                    </button>
                  </div>
                </div>
              )}
              {error && <div style={{ color: "red", marginTop: "10px" }}>{error}</div>}
            {isCropping && ( // If cropping mode is on, show crop and save buttons
              <div className="buttonGroup">
                <button
                  className="btn btnPrimaryOutline w-50"
                  onClick={handleCropImage}
                >
                  Crop Image
                </button>
                <button
                  className="btn w-50"
                  onClick={handleSaveImage}
                  // disabled={!croppedImage}
                >
                  Upload
                </button>
              </div>
            )}
            {croppedImage && (
              <div className="cropped-image-preview mt-4">
                <h3>Cropped Image Preview</h3>
                <img
                  src={URL.createObjectURL(croppedImage)}
                  alt="Cropped Image"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProfilePicture;
