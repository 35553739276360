import "rc-slider/assets/index.css";
import React, { useCallback, useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  CampainsDelete,
  getCampains,
  getUserSubscriptionPlanDetails,
} from "../../context/actions/User";
import { useDispatch, useSelector } from "react-redux";
import ICPTitle from "../BuildICP/ICPResult/ICPTitle";
import { toast } from "react-toastify";
import "./campiagn.scss";
import { debounce } from "lodash";
import CampaignsInformation from "./CampaignInformation";
import { formatDate, formatTime } from "../../utils/Validations/ComonFunctions";
import { handleCreateConfirmation } from "../../components/Modal/ConfirmBox";
import { FirstLetterCapital } from "../../utils/constants";
export default function Index() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const navigate = useNavigate();




  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result.status === 200) {
            setGetUserSubscriptionData(result?.data);
          } else if (result.status === 404) {
            // navigate("/subscription-plans");
            toast(result.message, { autoClose: 1200 });
          }
        })
      );
    }
  }, []);

  const fetchCampaign = (search) => {
    dispatch(
      getCampains({ user_id: userId, search }, token, (result) => {
        setCampaign(result.result);
      })
    );
  };

  useEffect(() => {
    fetchCampaign("");
  }, []);

  const debouncedSearch = useCallback(
    debounce((input) => {
      fetchCampaign(input);
      console.log("serch", input);
    }, 1000),
    []
  );
  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    debouncedSearch(input);
  };

  const formatStatus = {
    complete: "Complete",
    in_progress: "In progress",
    open: "Open",
    Accepted: "Accepted",
  };

  const handleDelete = (id) => {
    handleCreateConfirmation(
      id,
      (id) => {
        console.log("Delete user with ID:", id);
        dispatch(
          CampainsDelete(id, token, (result) => {
            if (result.status === 200) {
              fetchCampaign("");
            }
          })
        );
      },
      () => {
        return false;
      }
    );
  };

  const handelDownloadFile = (file) => {
    const link = document.createElement("a");
    link.href = file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const tooltip = (
    <Tooltip id="custom-tooltip" className="custom-tooltip">
      <div style={{ padding: "5px" }}>
        Click here to review the deliverables.
      </div>
    </Tooltip>
  );
  const downloadTooltip = (
    <Tooltip id="custom-tooltip" className="custom-tooltip">
      <div style={{ padding: "5px" }}>
        Deliverables Overview
      </div>
    </Tooltip>
  );
  const deleteTooltip = (
    <Tooltip id="custom-tooltip" className="custom-tooltip">
      <div style={{ padding: "5px" }}>
        Delete
      </div>
    </Tooltip>
  );

  return (
    <>
      <div className="pageHeadingBlock commonHeading CampaignBlk commonHeadBlock campBLOCK">
        {/* Web view: Horizontal layout */}
        <div className="d-flex align-items-center justify-content-between w-100 flex-wrap freeTrialAC gap-2">
          {/* Title Section */}
          <ICPTitle title="My Campaign" backTo="/dashboard" />

          {/* Campaign Information Section */}
          <div className="d-flex justify-content-between flex-wrap m-0 campINFo gap-2">
            <CampaignsInformation
              getUserSubscriptionData={getUserSubscriptionData}
            />
            <div style={{ width: "fit-content" }}>
              <Link to="/build-campaign" className="req-btn p-0 px-4">
                <div
                  style={{
                    width: "100%",
                    fontSize: "1.2rem",
                    whiteSpace: "nowrap"
                  }}
                >
                  Request Campaign
                </div>
              </Link>
            </div>
            {!(getUserSubscriptionData?.is_free_trial&&!getUserSubscriptionData?.is_existing_user)&&<div style={{ width: "fit-content" }}>
              <Link to="/track-campaigns" className="req-btn p-0 px-4">
                <div
                  style={{
                    width: "100%",
                    fontSize: "1.2rem",
                    whiteSpace: "nowrap"
                  }}
                >
                  Track All Campaigns
                </div>
              </Link>
            </div>}
            {/* <div className="dropdown mt-2" style={{ position: 'relative', zIndex: 1050 }}>
              <svg
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-three-dots-vertical"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
              </svg>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
                style={{
                  maxWidth: '200px%',
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                  width: 'max-content'
                }}
              >
                <li onClick={()=>{navigate('/track-campaigns')}}>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    style={{
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                      background:'#fff',
                      color:'#000'
                    }}
                  >
                    Track All campaigns
                  </a>
                </li>
              </ul>
            </div> */}

          </div>


          {/* Request Campaign button Section */}
          {/* <div className="col-lg-2 p-0" >
         
          </div> */}
        </div>

        {/* Mobile view */}
        {/* <div className="row w-100 d-block d-md-none">
          <div className="col-12">
            <div className="row mx-0 px-0 d-flex justify-content-between">
              <div className="col-auto">
                <ICPTitle title="My Campaign" backTo="/dashboard" />
              </div>
              <div className="">
                <CampaignsInformation
                  getUserSubscriptionData={getUserSubscriptionData}
                />
              </div>
            </div>
          </div>
     
        </div> */}

      </div>
      {/* button code */}
      {/* <div className="row d-md-none d-block d-flex justify-content-center">
          <div className="col-auto">
            <Link to="/build-campaign" className="btn p-0" height="3rem">
              <div
                className="mx-5"
                style={{
                  // width: "100%",
                  fontSize: "1.2rem",
                }}
              >
                Request Campaign
              </div>
            </Link>
          </div>
        </div> */}
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper myDownloadList myDownloadBLKK mb-0">
              <div className="tableHeadingBlock mb-0 d-block">
                <div className="leftContent">
                  <form className="searchForm searchBLK">
                    <div className="form-group m-0">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Search"
                        // value={searchInput}
                        onChange={handleSearchInputChange}
                      />
                    </div>
                  </form>
                </div>

                {/* <div className="raiseTicket">
                  <Link to="/build-campaign" className="btn">
                    Request Campaign
                  </Link>
                </div> */}
                {/* <Table>
                                    <thead
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 1,
                                        }}
                                    >
                                        <tr>
                                            <th>Campaign Name</th>
                                            <th>Assign Team member</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th >Download</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {campaign.length > 0 ? (
                                            campaign?.map((data) => (
                                                <tr key={data.id}>
                                                    <td>{FirstLetterCapital(data.campaign_name)}</td>
                                                    <td>{data.team_member_name ? FirstLetterCapital(data.team_member_name) : "Not assigned yet"}</td>
                                                    <td>{formatDate(data.created_at)} - {formatTime(data.created_at)}</td>
                                                    <td >
                                                        <span className={`status-signal-score ${data.status}`}>
                                                            {formatStatus[data?.status] || "-"}</span>
                                                    </td>
                                                    <td>
                                                        {data?.result_file && (
                                                            <svg
                                                                fill="#000000"
                                                                width="20px"
                                                                height="20px"
                                                                viewBox="0 0 32 32"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                onClick={() => handelDownloadFile(data?.result_file)}
                                                                style={{
                                                                    display: 'block',
                                                                    margin: '0 ,0,0,0',
                                                                }}
                                                            >
                                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                <g
                                                                    id="SVGRepo_tracerCarrier"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                ></g>
                                                                <g id="SVGRepo_iconCarrier">
                                                                    <path d="M18.313 13.625h-4.031v-6.594c0-0.563-0.469-1.031-1.031-1.031h-4.031c-0.594 0-1.063 0.469-1.063 1.031v6.594h-4.031c-0.531 0-0.719 0.344-0.313 0.75l6.688 6.656c0.188 0.188 0.438 0.281 0.719 0.281s0.563-0.094 0.75-0.281l6.656-6.656c0.375-0.406 0.25-0.75-0.313-0.75zM0 18.344v7.125c0 0.313 0.156 0.5 0.5 0.5h21.375c0.344 0 0.531-0.188 0.531-0.5v-7.125c0-0.313-0.25-0.531-0.531-0.531h-2.031c-0.281 0-0.531 0.25-0.531 0.531v4.531h-16.25v-4.531c0-0.313-0.219-0.531-0.5-0.531h-2.063c-0.281 0-0.5 0.25-0.5 0.531z"></path>{" "}
                                                                </g>
                                                            </svg>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {(data.status === 'complete' || data.status == "Accepted") ? <div
                                                            className="icon-wrapper-campaign"
                                                            onClick={() => navigate(`/build-my-campaign/${data?.id}`)}
                                                        >
                                                            View
                                                        </div> : <div
                                                            style={{
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                width: '55px',
                                                                height: '27px',
                                                                marginRight: '10px',
                                                            }
                                                            }
                                                        >
                                                            --

                                                        </div>}
                                                        <div
                                                            className="icon-wrapper-campaign"
                                                            title="Delete"
                                                            onClick={() => handleDelete(data.id)}
                                                        >
                                                            Delete
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td className="text-center" colSpan={7}>
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table> */}
              </div>
              <div className="row">
                <div className="col-12">
                  {/* Responsive table wrapper */}
                  <div
                    style={{
                      overflowX: "auto",
                      WebkitOverflowScrolling: "touch",
                    }}
                  >
                    <Table
                      responsive
                      className="tableContentBlock table_block_padd"
                      style={{
                        // border: "1px solid red",
                        width: "100%",
                        tableLayout: "auto",
                      }}
                    >
                      <thead
                        style={{
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                          backgroundColor: "#fff",
                        }}
                      >
                        <tr>
                          <th className="Table-Heading" style={{ textAlign: "left", paddingLeft: "3rem" }}>Campaign Name</th>
                          <th className="Table-Heading" style={{ textAlign: "left" }}>Assign Team Member</th>
                          <th className="Table-Heading">Date</th>
                          <th className="Table-Heading">Status</th>
                          <th className="Table-Heading">Download</th>
                          <th className="Table-Heading">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaign.length > 0 ? (
                          campaign.map((data) => (
                            <tr key={data.id}>
                              <td className="Table-Heading" style={{ textAlign: "left", paddingLeft: "3rem" }}>
                                {FirstLetterCapital(data.campaign_name)}
                              </td>
                              <td className="Table-Heading" style={{ textAlign: "left" }}>
                                {data.team_member_name
                                  ? FirstLetterCapital(data.team_member_name)
                                  : "Not assigned yet"}
                              </td>
                              <td
                                className="Table-Heading"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {formatDate(data.created_at)} -{" "}
                                {formatTime(data.created_at)}
                              </td>
                              <td className="Table-Heading">
                                <span
                                  className={`status-signal-score ${data.status}`}
                                >
                                  {formatStatus[data?.status] || "-"}
                                </span>
                              </td>
                              {/* trying */}
                              <td className="Table-Heading">
                                {data?.result_file ? (
                                  <OverlayTrigger placement="top" overlay={downloadTooltip}>
                                    <svg
                                      fill="#000000"
                                      width="20px"
                                      height="20px"
                                      viewBox="0 0 32 32"
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={() => handelDownloadFile(data?.result_file)}
                                      style={{ cursor: "pointer", paddingLeft: "0.4rem" }}
                                    >
                                      <path d="M18.313 13.625h-4.031v-6.594c0-0.563-0.469-1.031-1.031-1.031h-4.031c-0.594 0-1.063 0.469-1.063 1.031v6.594h-4.031c-0.531 0-0.719 0.344-0.313 0.75l6.688 6.656c0.188 0.188 0.438 0.281 0.719 0.281s0.563-0.094 0.75-0.281l6.656-6.656c0.375-0.406 0.25-0.75-0.313-0.75zM0 18.344v7.125c0 0.313 0.156 0.5 0.5 0.5h21.375c0.344 0 0.531-0.188 0.531-0.5v-7.125c0-0.313-0.25-0.531-0.531-0.531h-2.031c-0.281 0-0.531 0.25-0.531 0.531v4.531h-16.25v-4.531c0-0.313-0.219-0.531-0.5-0.531h-2.063c-0.281 0-0.5 0.25-0.5 0.531z"></path>
                                    </svg>
                                  </OverlayTrigger>
                                ) : (
                                  <svg
                                    fill="#CCCCCC"  // Gray color to indicate a disabled state
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ paddingLeft: "0.4rem" }}
                                  >
                                    <path d="M18.313 13.625h-4.031v-6.594c0-0.563-0.469-1.031-1.031-1.031h-4.031c-0.594 0-1.063 0.469-1.063 1.031v6.594h-4.031c-0.531 0-0.719 0.344-0.313 0.75l6.688 6.656c0.188 0.188 0.438 0.281 0.719 0.281s0.563-0.094 0.75-0.281l6.656-6.656c0.375-0.406 0.25-0.75-0.313-0.75zM0 18.344v7.125c0 0.313 0.156 0.5 0.5 0.5h21.375c0.344 0 0.531-0.188 0.531-0.5v-7.125c0-0.313-0.25-0.531-0.531-0.531h-2.031c-0.281 0-0.531 0.25-0.531 0.531v4.531h-16.25v-4.531c0-0.313-0.219-0.531-0.5-0.531h-2.063c-0.281 0-0.5 0.25-0.5 0.531z"></path>
                                  </svg>
                                )}
                              </td>
                              {/* --- */}


                              <td className="Table-Heading">
                                <OverlayTrigger placement="top" overlay={tooltip}>
                                  <div className={`icon-wrapper-campaign my-3 my-md-3 my-lg-0 Table-Heading ${!(data.status === 'complete' || data.status === 'Accepted') ? 'disabled' : ''}`} onClick={data.status === 'complete' || data.status === 'Accepted' ? () => navigate(`/build-my-campaign/${data?.id}`) : undefined} style={{ cursor: data.status === 'complete' || data.status === 'Accepted' ? 'pointer' : 'not-allowed' }}>
                                    <span>View</span>
                                  </div>
                                </OverlayTrigger>

                                {/* {data.status === "complete" || data.status === "Accepted" ? (
                                  <div
                                    className="icon-wrapper-campaign my-3 my-md-3 my-lg-0 Table-Heading"
                                    onClick={() =>
                                      navigate(`/build-my-campaign/${data?.id}`)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      // color: "#007bff",
                                    }}
                                  >
                                  {data.status === 'open' ? <OverlayTrigger placement="top" overlay={tooltip}>
                                    View
                                  </OverlayTrigger> : ''} 
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      // border:"1px solid red",
                                      display: "inline-flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "65px",
                                      height: "27px",
                                      paddingRight:"16px"
                                    }}
                                  >
                                
                                <span style={{ marginLeft: "8px" }}>
                                <div
                                    className="icon-wrapper-campaign my-3 my-md-3 my-lg-0 Table-Heading"
                                    onClick={() =>
                                      navigate(`/build-my-campaign/${data?.id}`)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      // color: "#007bff",
                                    }}
                                  >
                                    View
                                  </div>
                                </span> 
                                  </div>
                                )} */}

                                <OverlayTrigger placement="top" overlay={deleteTooltip}>
                                  <div
                                    className="icon-wrapper-campaign"
                                    onClick={() => handleDelete(data.id)}
                                    style={{ cursor: "pointer", color: "red" }}
                                  >
                                    Delete
                                  </div>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={6}>
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
