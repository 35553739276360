import React, { useEffect, useState } from "react";
import "./ProfilePicture.scss";
import { useDispatch, useSelector } from "react-redux";
import { removeProfilePhoto } from "../../../../context/actions/User";
import { useNavigate } from "react-router-dom";

const ProfilePicture = ({ showAddProfileModal, selectedFile, getUserData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const userData = useSelector((state) => state?.user);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const [isUploaded, setIsUploaded] = useState(false);

  const handleRemovePhoto = (e) => {
    e.preventDefault();
    const payload = {
      user: userId,
    };
    dispatch(
      removeProfilePhoto(payload, token, (result) => {
        if (result.status === 200) {
          setIsUploaded(true);
          window.location.reload(true);
        }
      })
    );
  };

  useEffect(() => {
    if (isUploaded) {
      setTimeout(() => {
        setIsUploaded(false);

        navigate("/settings");
      }, 2000); // 2000 milliseconds delay before redirecting
    }
  }, [isUploaded, navigate]);
  return (
    <div
      className={`modal AddProfilePictureModal fade ${isUploaded && "hide"}`}
      id="ProfilePictureModal"
      tabIndex="-1"
      aria-labelledby="ProfilePictureModal"
      // aria-hidden="true"
      aria-hidden={!isUploaded}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <span
            className="closeModal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M7.00047 8.0543L1.92737 13.1274C1.78892 13.2658 1.61489 13.3367 1.40527 13.3399C1.19567 13.3431 1.01844 13.2723 0.873575 13.1274C0.728691 12.9825 0.65625 12.8069 0.65625 12.6005C0.65625 12.3941 0.728691 12.2184 0.873575 12.0736L5.94665 7.00047L0.873575 1.92737C0.735108 1.78892 0.664275 1.61489 0.661075 1.40527C0.657858 1.19567 0.728691 1.01844 0.873575 0.873575C1.01844 0.728691 1.19407 0.65625 1.40047 0.65625C1.60687 0.65625 1.78251 0.728691 1.92737 0.873575L7.00047 5.94665L12.0736 0.873575C12.212 0.735108 12.3861 0.664275 12.5957 0.661075C12.8053 0.657858 12.9825 0.728691 13.1274 0.873575C13.2723 1.01844 13.3447 1.19407 13.3447 1.40047C13.3447 1.60687 13.2723 1.78251 13.1274 1.92737L8.0543 7.00047L13.1274 12.0736C13.2658 12.212 13.3367 12.3861 13.3399 12.5957C13.3431 12.8053 13.2723 12.9825 13.1274 13.1274C12.9825 13.2723 12.8069 13.3447 12.6005 13.3447C12.3941 13.3447 12.2184 13.2723 12.0736 13.1274L7.00047 8.0543Z"
                fill="black"
              />
            </svg>
          </span>
          <div className="modal-body text-center">
            <h2>Profile Picture</h2>
            <p>
              {!getUserData?.profile_image && " Provide a profile picture that can help people to recognise you."}
            </p>
            <div className="userImage">
              {getUserData?.profile_image ? (
                <img src={getUserData?.profile_image} alt="profile" />
              ) : (
                <h2
                  className="userNameLetter"
                  style={{
                    background: "$orange3",
                    width: "16rem",
                    height: "16rem",
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {getUserData?.first_name
                    ? getUserData.first_name.charAt(0).toUpperCase()
                    : null}
                </h2>
              )}
            </div>
            {getUserData?.profile_image ? (
              <div className="buttonGroup deletImgBlk">
                <button
                  className="btn btnPrimaryOutline w-100"
                  onClick={handleRemovePhoto}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M6.08975 17.0822C5.67549 17.0822 5.32085 16.9347 5.02585 16.6397C4.73084 16.3447 4.58333 15.99 4.58333 15.5758V4.99886H4.375C4.19792 4.99886 4.04948 4.93894 3.92969 4.81909C3.8099 4.69926 3.75 4.55076 3.75 4.37359C3.75 4.19644 3.8099 4.04804 3.92969 3.92839C4.04948 3.80872 4.19792 3.74888 4.375 3.74888H7.49998C7.49998 3.54483 7.57183 3.37096 7.71552 3.22726C7.85922 3.08357 8.03309 3.01172 8.23715 3.01172H11.7628C11.9669 3.01172 12.1407 3.08357 12.2844 3.22726C12.4281 3.37096 12.5 3.54483 12.5 3.74888H15.625C15.802 3.74888 15.9505 3.80881 16.0703 3.92866C16.1901 4.0485 16.25 4.197 16.25 4.37416C16.25 4.55132 16.1901 4.69973 16.0703 4.81939C15.9505 4.93904 15.802 4.99886 15.625 4.99886H15.4166V15.5758C15.4166 15.99 15.2691 16.3447 14.9741 16.6397C14.6791 16.9347 14.3245 17.0822 13.9102 17.0822H6.08975ZM14.1666 4.99886H5.83331V15.5758C5.83331 15.6506 5.85735 15.712 5.90544 15.7601C5.95352 15.8082 6.01496 15.8322 6.08975 15.8322H13.9102C13.985 15.8322 14.0464 15.8082 14.0945 15.7601C14.1426 15.712 14.1666 15.6506 14.1666 15.5758V4.99886ZM8.46181 14.1655C8.63897 14.1655 8.78737 14.1056 8.90702 13.9858C9.02669 13.8661 9.08652 13.7176 9.08652 13.5406V7.29051C9.08652 7.11344 9.0266 6.96501 8.90675 6.84522C8.7869 6.72543 8.6384 6.66553 8.46125 6.66553C8.28408 6.66553 8.13567 6.72543 8.01602 6.84522C7.89637 6.96501 7.83654 7.11344 7.83654 7.29051V13.5406C7.83654 13.7176 7.89647 13.8661 8.01631 13.9858C8.13615 14.1056 8.28465 14.1655 8.46181 14.1655ZM11.5387 14.1655C11.7159 14.1655 11.8643 14.1056 11.9839 13.9858C12.1036 13.8661 12.1634 13.7176 12.1634 13.5406V7.29051C12.1634 7.11344 12.1035 6.96501 11.9836 6.84522C11.8638 6.72543 11.7153 6.66553 11.5381 6.66553C11.361 6.66553 11.2126 6.72543 11.0929 6.84522C10.9733 6.96501 10.9134 7.11344 10.9134 7.29051V13.5406C10.9134 13.7176 10.9734 13.8661 11.0932 13.9858C11.2131 14.1056 11.3616 14.1655 11.5387 14.1655Z"
                      fill="#E55426"
                    />
                  </svg>{" "}
                  Remove
                </button>
                <button
                  className="btn w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#AddProfilePictureModal"
                  onClick={(e) => showAddProfileModal(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M4.66667 15.8364H5.85417L14 7.69107L12.8125 6.50365L4.66667 14.649V15.8364ZM3 17.5029V13.9615L14 2.98304C14.1667 2.83027 14.3507 2.71223 14.5521 2.6289C14.7535 2.54557 14.9653 2.50391 15.1875 2.50391C15.4097 2.50391 15.625 2.54557 15.8333 2.6289C16.0417 2.71223 16.2222 2.83722 16.375 3.00387L17.5208 4.17047C17.6875 4.32323 17.809 4.50378 17.8854 4.7121C17.9618 4.92042 18 5.12874 18 5.33706C18 5.55927 17.9618 5.77106 17.8854 5.97243C17.809 6.17381 17.6875 6.35783 17.5208 6.52448L6.54167 17.5029H3ZM13.3958 7.10778L12.8125 6.50365L14 7.69107L13.3958 7.10778Z"
                      fill="white"
                    />
                  </svg>{" "}
                  Change
                </button>
              </div>
            ) : (
              <div className="buttonDiv">
                <button
                  className="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#AddProfilePictureModal"
                  onClick={(e) => showAddProfileModal(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M4.16667 15.8364H5.35417L13.5 7.69107L12.3125 6.50365L4.16667 14.649V15.8364ZM2.5 17.5029V13.9615L13.5 2.98304C13.6667 2.83027 13.8507 2.71223 14.0521 2.6289C14.2535 2.54557 14.4653 2.50391 14.6875 2.50391C14.9097 2.50391 15.125 2.54557 15.3333 2.6289C15.5417 2.71223 15.7222 2.83722 15.875 3.00387L17.0208 4.17047C17.1875 4.32323 17.309 4.50378 17.3854 4.7121C17.4618 4.92042 17.5 5.12874 17.5 5.33706C17.5 5.55927 17.4618 5.77106 17.3854 5.97243C17.309 6.17381 17.1875 6.35783 17.0208 6.52448L6.04167 17.5029H2.5ZM12.8958 7.10778L12.3125 6.50365L13.5 7.69107L12.8958 7.10778Z"
                      fill="white"
                    />
                  </svg>{" "}
                  Add profile picture
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePicture;
