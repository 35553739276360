export const ButtonGroup = ({ handleSubTypeClick, selectedSubType }) => {
  return (
    <div className="button-group buttonGp">
      <button
        className={`downloadbtn ${
          selectedSubType === "emp_size" ? "highlight" : ""
        }`}
        onClick={() => handleSubTypeClick("emp_size")}
      >
        
        <p className="m-0"><span className="d-block"><b>By EMP Size</b></span></p>
       {/* <p><strong>EMP Size Wise</strong></p>  */}
      </button>
      <button
        className={`downloadbtn ${
          selectedSubType === "industry" ? "highlight" : ""
        }`}
        onClick={() => handleSubTypeClick("industry")}
      >
        <p className="m-0"><span className="d-block"><b>By Industry</b></span></p>
       
      </button>
      <button
        className={`downloadbtn ${
          selectedSubType === "revenue" ? "highlight" : ""
        }`}
        onClick={() => handleSubTypeClick("revenue")}
      >
        <p className="m-0"><span className="d-block"><b>By Revenue</b></span></p>
   
      </button>
    </div>
  );
};
