import React, { useEffect, useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllSavedSearches } from "../../../context/actions/User";
import { Skeleton } from 'primereact/skeleton';

const G2Modal = ({ showAddProfileModal, showModal, onClose, g2url }) => { 
    const [loading, setLoading] = useState(true);
    return (
        <>
            <Modal className="showG2ModalReview modal-lg" show={showModal} onHide={onClose} centered>
                <Modal.Body className="modal-body text-center m-0"> 
                    <div className="row"> 
                        <div className="col-12" style={{ position: 'relative' }}>
                            {loading && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '787px',
                                        height: '400px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        zIndex: 10,
                                    }}
                                >
                                    <div className="loader"></div>
                                </div>
                            )}
                            <iframe src={g2url} width='100%' height='400px' style={{ margin:'0px !important', padding:'0px !important', maxWidth:'0px !important' }} onLoad={() => setLoading(false)}></iframe>
                        </div> 
                    </div> 
                </Modal.Body>
            </Modal>
        </>
    )
};
export default G2Modal;
