import "rc-slider/assets/index.css";
import React, { useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import "./campaignTracker.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUserCampaignTrack } from "../../context/actions/User";
import ICPTitle from "../BuildICP/ICPResult/ICPTitle";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatDate, formatDateTime } from "../../utils/Validations/ComonFunctions";

const CampaignTracker = () => {
  const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [listData, setListData] = useState([]);
  const {state}=useLocation()
  const tooltip = (progress) => (
    <Tooltip id="custom-tooltip" className="custom-tooltip">
      <div>
        <span>Your Campaign has been {progress}% Completed</span>
      </div>
    </Tooltip>
  );
  const debounceTimeout = useRef(null); // Use ref to keep track of timeout
  const fetchData = () => {
    dispatch(
      getUserCampaignTrack(
        userId,
        { search: searchInput, page: currentPage,campaign_id:state?.campaign_id },
        token,
        (res) => {
          setListData(res.data);
          setTotalPages(res.total_pages);
          setCurrentPage(res.page);
        }
      )
    );
  };
  // Effect for fetching data (search and pagination)
  useEffect(() => {
    fetchData();
  }, [userId,currentPage, dispatch]);

  // Handle page change for pagination
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  useEffect(() => {
    clearTimeout(debounceTimeout.current); // Clear the previous timeout
    debounceTimeout.current = setTimeout(() => {
      fetchData();
    }, 1000);
  }, [searchInput]);

  return (
    <>
      <div className="pageHeadingBlock commonHeading  commonHeadBlock">
        <div className="pageTitle">
          <ICPTitle title="Campaign Track List" backTo="/campaign-list" />
        </div>
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div
              className="contentWrapper myDownloadList mb-0  p-4 "
              style={{
                height: "489px",
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                overflowY:'auto'
              }}
            >
              <div className="tableHeadingBlock tableHeadingBlock_CT mb-0 px-0">
                <div className="leftContent">
                  <form className="searchForm me-2 searchBLK">
                    <div className="form-group m-0">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Search"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </div>
                  </form>
                  <div className="filtersBlock">
                    <div className="downloadSelected"></div>
                  </div>
                </div>
                <div className="rightContent">
                  <div className="paginationBlock">
                    <div className="d-flex align-items-center">
                      Page :
                      <div className="dropdown paginationDropdown">
                        <div
                          className="pageDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <input
                            type="text"
                            className="pageNumber"
                            placeholder={currentPage}
                          /> */}
                          <button className="page-drop">{currentPage}</button>
                        </div>
                        <div className="dropdown-menu">
                          <ul>
                            {Array.from(
                              { length: totalPages },
                              (_, i) => i + 1
                            ).map((pageNumber) => (
                              <li
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber.toString().padStart(2, "0")}{" "}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      of
                      <span className="totalPages"> {totalPages} </span>
                    </div>
                    <div className="pagination">
                      <div
                        className="prev paginationItem"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <Link to="" className="paginationLink">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <path
                              d="M4.75 9.25C4.65146 9.25046 4.55382 9.23124 4.46281 9.19347C4.37179 9.15569 4.28924 9.10011 4.22 9.03L0.72 5.53C0.57955 5.38937 0.50066 5.19875 0.50066 5C0.50066 4.80125 0.57955 4.61063 0.72 4.47L4.22 1C4.36096 0.908609 4.52852 0.867188 4.69582 0.882376C4.86312 0.897563 5.02048 0.96848 5.14268 1.08376C5.26488 1.19904 5.34483 1.352 5.36973 1.51814C5.39463 1.68428 5.36303 1.85396 5.28 2L2.28 5L5.28 8C5.42045 8.14063 5.49934 8.33125 5.49934 8.53C5.49934 8.72875 5.42045 8.91937 5.28 9.06C5.13527 9.19069 4.9448 9.25897 4.75 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                      <div
                        className="next paginationItem"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <Link to="" className="paginationLink">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                          >
                            <path
                              d="M1.25 9.25C1.14929 9.24661 1.05034 9.22269 0.959199 9.1797C0.868063 9.13671 0.786667 9.07556 0.72 9C0.57955 8.85937 0.50066 8.66875 0.50066 8.47C0.50066 8.27125 0.57955 8.08062 0.72 7.94L3.72 4.94L0.72 1.94C0.660676 1.79599 0.647659 1.63708 0.682755 1.48533C0.717851 1.33359 0.799319 1.19653 0.915851 1.09319C1.03238 0.989854 1.1782 0.925358 1.33305 0.908658C1.4879 0.891957 1.64411 0.923882 1.78 1L5.28 4.5C5.42045 4.64063 5.49934 4.83125 5.49934 5.03C5.49934 5.22875 5.42045 5.41938 5.28 5.56L1.78 9C1.71333 9.07556 1.63194 9.13671 1.5408 9.1797C1.44966 9.22269 1.35071 9.24661 1.25 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-container table-responsive-campaign">
                <Table className="campaign-track-table">

                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,

                      overflowY:'auto',
                      // Orange background
                      fontWeight: "bold", // Bold font weight for the table headers
                      border: "none !important",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", // Box shadow on header

                    }}
                    className=''
                  >
                    <tr >
                      <th className='ctHead' style={{ borderTopLeftRadius: "10px"}}>#</th>
                      <th className='ctHead'>Name</th>
                      <th className='ctHead'>Type</th>
                      <th className='ctHead'>CPL</th>

                      <th className='ctHead'>Start Date</th>
                      <th className='ctHead'>End Date</th>
                      <th className='ctHead'>Total Target</th>
                      <th className='ctHead'>Leads Submitted</th>
                      <th className='ctHead'>Leads Accepted</th>
                      <th className='ctHead'>Leads Pending</th>
                      {/* <th className='ctHead'>Email Statistics</th>
                      <th className='ctHead'>Program Analysis</th> */}
                      <th className='ctHead' style={{ width: "200px", textAlign: "center" }}>
                        Goal Achieved %
                      </th>
                      <th className='ctHead' >
                         Status
                      </th>
                      <th className='ctHead'>Email Statistics</th>
                      <th className='ctHead' style={{ borderTopRightRadius: "10px" }}>Program Analysis</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listData && listData.length > 0 ? (
                      listData.map((item, index) => {
                      
                        let color = '';
                        if (item.completion <= 40) {
                          color = '#f45955';
                        } else if (item.completion <= 60) {
                          color = '#f99a34';
                        } else if (item.completion <= 80) {
                          color = '#f0d024 ' ;
                        } else {
                          color = '#8dc76d';
                          
                        }
 
                        return (
                          <tr
                            key={item?.id}
                            style={{ borderBottom: "2px solid #ddd" ,}}
                          >
                            <td>{index + 1}</td>
                            <td>{item.campaign}</td>
                            <td>{item.type}</td>
                            <td>$ {item.cpl||0}</td>
                            <td>{formatDate(item.start_date)}</td>
                            <td>{formatDate(item.end_date)}</td>
                            <td>{item.total_target}</td>
                            <td>{item.leads_submitted}</td>
                            <td >{item.leads_accepted}</td>
                            <td>{item.leads_pending}</td>
                            

                            <td>
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltip(item?.completion)}
                              >
                                <div className="progress">
                                  <div
                                    className='progress-bar progress-bar-striped progress-bar-animated'
                                    role="progressbar"
                                    aria-valuenow={item.completion}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: `${item.completion}%`, backgroundColor: color }}

                                  >
                                    <div
                                      style={{
                                        color: "black",
                                        textAlign: "center",
                                        // padding: "0.5rem",
                                        fontSize: '1rem'
                                      }}
                                    >
                                      <strong
                                        className="progressbarMain"
                                        // style={{ padding: "0.5rem", }}
                                      >
                                        {item.completion}% Done
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </OverlayTrigger>
                            </td>
                            <td>
                              <span className="campaignStatus"
                                style={{
                                  color: "#F59D40",
                                  background:
                                    "rgba(254, 245, 236, 0.9) !important;",
                                }}
                              >
                                {item.campaign_status.replace(/_/g, " ") // Replace underscores with spaces
                                  .replace(/\b\w/g, (char) => char.toUpperCase())}
                              </span>
                            </td>
                            <td>
                              <div className="dropdown mt-2 text-center" style={{ position: 'relative', zIndex: 1 }}>
                                <svg
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  fill={"#414141"}
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 32 32"
                                  version="1.1"
                                  zIndex="-1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={item?.email_statistics?.length === 0 ? "disabled-svg" : ""}
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></g>
                                  <g id="SVGRepo_iconCarrier">
                                    <path d="M18.313 13.625h-4.031v-6.594c0-0.563-0.469-1.031-1.031-1.031h-4.031c-0.594 0-1.063 0.469-1.063 1.031v6.594h-4.031c-0.531 0-0.719 0.344-0.313 0.75l6.688 6.656c0.188 0.188 0.438 0.281 0.719 0.281s0.563-0.094 0.75-0.281l6.656-6.656c0.375-0.406 0.25-0.75-0.313-0.75zM0 18.344v7.125c0 0.313 0.156 0.5 0.5 0.5h21.375c0.344 0 0.531-0.188 0.531-0.5v-7.125c0-0.313-0.25-0.531-0.531-0.531h-2.031c-0.281 0-0.531 0.25-0.531 0.531v4.531h-16.25v-4.531c0-0.313-0.219-0.531-0.5-0.531h-2.063c-0.281 0-0.5 0.25-0.5 0.531z"></path>{" "}
                                  </g>
                                </svg>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                  style={{
                                    maxWidth: '250px%',
                                    overflow: 'hidden',
                                    wordWrap: 'break-word',
                                    width: 'max-content'
                                  }}
                                >
                                  {
                                    item?.email_statistics.map((ele) => (
                                      <li >
                                        <a
                                          className="dropdown-item"
                                          href={ele.upload_file}
                                          target="_blank"
                                          download={ele.created_at}
                                          style={{
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                            background: '#fff',
                                            color: '#000',
                                            zIndex:999
                                          }}
                                        >
                                          {formatDateTime(ele.created_at)} <svg
                                           
                                            fill={"#414141"}
                                            width="20px"
                                            height="15px"
                                            viewBox="0 0 32 32"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"

                                          >
                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                            <g
                                              id="SVGRepo_tracerCarrier"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></g>
                                            <g id="SVGRepo_iconCarrier">
                                              <path d="M18.313 13.625h-4.031v-6.594c0-0.563-0.469-1.031-1.031-1.031h-4.031c-0.594 0-1.063 0.469-1.063 1.031v6.594h-4.031c-0.531 0-0.719 0.344-0.313 0.75l6.688 6.656c0.188 0.188 0.438 0.281 0.719 0.281s0.563-0.094 0.75-0.281l6.656-6.656c0.375-0.406 0.25-0.75-0.313-0.75zM0 18.344v7.125c0 0.313 0.156 0.5 0.5 0.5h21.375c0.344 0 0.531-0.188 0.531-0.5v-7.125c0-0.313-0.25-0.531-0.531-0.531h-2.031c-0.281 0-0.531 0.25-0.531 0.531v4.531h-16.25v-4.531c0-0.313-0.219-0.531-0.5-0.531h-2.063c-0.281 0-0.5 0.25-0.5 0.531z"></path>{" "}
                                            </g>
                                          </svg>

                                        </a>
                                      </li>

                                    ))
                                  }

                                </ul>
                              </div>
                            </td>
                            <td>
                              <div className="dropdown mt-2 text-center" style={{ position: 'relative', zIndex: 1 }}>
                                <svg
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  fill={"#414141"}
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 32 32"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={item?.program_analysis?.length === 0 ? "disabled-svg" : ""}
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></g>
                                  <g id="SVGRepo_iconCarrier">
                                    <path d="M18.313 13.625h-4.031v-6.594c0-0.563-0.469-1.031-1.031-1.031h-4.031c-0.594 0-1.063 0.469-1.063 1.031v6.594h-4.031c-0.531 0-0.719 0.344-0.313 0.75l6.688 6.656c0.188 0.188 0.438 0.281 0.719 0.281s0.563-0.094 0.75-0.281l6.656-6.656c0.375-0.406 0.25-0.75-0.313-0.75zM0 18.344v7.125c0 0.313 0.156 0.5 0.5 0.5h21.375c0.344 0 0.531-0.188 0.531-0.5v-7.125c0-0.313-0.25-0.531-0.531-0.531h-2.031c-0.281 0-0.531 0.25-0.531 0.531v4.531h-16.25v-4.531c0-0.313-0.219-0.531-0.5-0.531h-2.063c-0.281 0-0.5 0.25-0.5 0.531z"></path>{" "}
                                  </g>
                                </svg>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                  style={{
                                    maxWidth: '300px',
                                    overflow: 'hidden',
                                    wordWrap: 'break-word',
                                    width: 'max-content',
                                    
                                  }}
                                >
                                  {
                                    item?.program_analysis.map((ele) => (
                                      <li >
                                        <a
                                          className="dropdown-item"
                                          href={ele.upload_file}
                                          target="_blank"
                                          download={ele.created_at}
                                          style={{
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                            background: '#fff',
                                            color: '#000'
                                          }}
                                        >
                                          {formatDateTime(ele.created_at)}  <svg
                                           
                                            fill={"#414141"}
                                            width="20px"
                                            height="15px"
                                            viewBox="0 0 32 32"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"

                                          >
                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                            <g
                                              id="SVGRepo_tracerCarrier"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></g>
                                            <g id="SVGRepo_iconCarrier">
                                              <path d="M18.313 13.625h-4.031v-6.594c0-0.563-0.469-1.031-1.031-1.031h-4.031c-0.594 0-1.063 0.469-1.063 1.031v6.594h-4.031c-0.531 0-0.719 0.344-0.313 0.75l6.688 6.656c0.188 0.188 0.438 0.281 0.719 0.281s0.563-0.094 0.75-0.281l6.656-6.656c0.375-0.406 0.25-0.75-0.313-0.75zM0 18.344v7.125c0 0.313 0.156 0.5 0.5 0.5h21.375c0.344 0 0.531-0.188 0.531-0.5v-7.125c0-0.313-0.25-0.531-0.531-0.531h-2.031c-0.281 0-0.531 0.25-0.531 0.531v4.531h-16.25v-4.531c0-0.313-0.219-0.531-0.5-0.531h-2.063c-0.281 0-0.5 0.25-0.5 0.531z"></path>{" "}
                                            </g>
                                          </svg>

                                        </a>
                                      </li>

                                    ))
                                  }

                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={14}>
                          No Records Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {/* </FullScreen> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignTracker;