import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Link, useNavigate } from "react-router-dom";
import "./Notification.scss";
import {
  GetNotifications,
  getUserSubscriptionPlanDetails,
} from "../../context/actions/User";
import { useDispatch, useSelector } from "react-redux";
import ICPTitle from "../BuildICP/ICPResult/ICPTitle";
import { toast } from "react-toastify";
import Information from "../BuildICP/ICPResult/Information";
import { useLocation } from "react-router-dom";
import { LOADER } from "../../utils/constants";

const ViewAllNotification = () => {
  const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const location = useLocation();
  // const { notification, readNotification } = location.state || {};

  // const handleUsers = () => {

  //   if (userId) {
  //     const payload = {
  //       user_id: userId,
  //     };
  //     dispatch(
  //       getUserSubscriptionPlanDetails(payload, token, (result) => {
  //         if (result.status === 200) {
  //           setGetUserSubscriptionData(result?.data);
  //         } else if (result.status === 404) {
  //           navigate("/subscription-plans");
  //           toast(result.message, { autoClose: 1200 });
  //         }
  //       })
  //     );
  //   }
  // };

  const handleLoadMore = () => {

    setPage(page + 1)
    
  }


  const getNotifications = () => {
    try {
      const limit = 10;
      dispatch({ type: LOADER, payload: true });
      dispatch(
        GetNotifications(token, { limit, page }, (result) => {
          setTotalPages(result?.total_pages)
          setNotificationData((prev) => [...prev, ...result?.data]);
          dispatch({ type: LOADER, payload: false });

        })
      )
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getNotifications();
  }, [page])

  return (
    <>
      <div className="pageHeadingBlock commonHeading commonHeadBlock">
        <ICPTitle title="View all notifications" backTo="/dashboard" />
      </div>
      <div className="pageContentBlock  mt-4" style={{boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',borderRadius:"2rem",height:"550px",overflow:"scroll"}}>

        <div className="contentCard show " >
          <div className="notificationHeading" >
            <h5>Notifications</h5>
            {/* <p className="markRead" >Mark all as Read</p> */}
          </div>
          <div className="notificationList " >
            {console.log(notificationData)}
            {
              (notificationData && notificationData.length > 0) && notificationData.map((item, index) => {
                //  console.log(item.is_read)
                return (<>
                  <div key={index} className="listItem updateNotification row m-0 p-3 ViewAllList "
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f9b15f47" : ""
                    }}
                  >
                    <div className="col-8 ps-0">
                      <div className='row align-items-center listBLK m-0 mb-2'>
                        <div className="icon iconBlk col-3 ps-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 40 40"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M20.0002 36.6663C29.2049 36.6663 36.6668 29.2044 36.6668 19.9997C36.6668 10.7949 29.2049 3.33301 20.0002 3.33301C10.7954 3.33301 3.3335 10.7949 3.3335 19.9997C3.3335 23.9946 4.73907 27.6613 7.08266 30.5322L3.82092 33.8212C2.77968 34.8711 3.51713 36.6663 4.98966 36.6663H20.0002ZM12.4932 16.9971C12.4932 16.1686 13.1647 15.4971 13.9932 15.4971H26.0723C26.9007 15.4971 27.5723 16.1686 27.5723 16.9971C27.5723 17.8255 26.9007 18.4971 26.0723 18.4971H13.9932C13.1647 18.4971 12.4932 17.8255 12.4932 16.9971ZM13.9932 21.5022C13.1647 21.5022 12.4932 22.1738 12.4932 23.0022C12.4932 23.8306 13.1647 24.5022 13.9932 24.5022H19.3193C20.1478 24.5022 20.8193 23.8306 20.8193 23.0022C20.8193 22.1738 20.1478 21.5022 19.3193 21.5022H13.9932Z"
                              fill="#E55426"
                            />
                          </svg>
                        </div>
                        <div className='col-9 pe-0'>
                          <div className="txtSize" >{item.message} </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                    <div className="justify-content-end muted-text timeCls">{item.duration} ago</div>
                    </div>

                    {/* <div className="p-3">

                      <div className="row" style={{ position: "relative" }}>


                        <div className="col-6 txtSize" >{item.message} </div>
                     
                      </div>
                    </div> */}
                  </div>

                </>
                )
              })
            }
            {
              totalPages === page ? "" :
                <div className="justify-content-center d-flex">
                  <button className="p-0 mt-3 Loading_btn" onClick={handleLoadMore}>Load More</button>
                </div>
            }

          </div>


        </div>
      </div>
      {/* <FullScreen handle={handle}>
        <div className="tableContentBlock table-responsive">
        
        </div>
      </FullScreen> */}
    </>
  );
};

export default ViewAllNotification;
