import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import "./PaymentDetails.scss";
import { Elements, AddressElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CreditPaymentForm from "./CreditPaymentForm";
import { getUserSubscriptionPlanDetails } from "../../context/actions/User";
import { useSelector, useDispatch } from "react-redux";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const CreditPaymentDetails = () => {
  
  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);

  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const [errors, setErrors] = useState({});

  let [cardType, setCardType] = useState("");
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);
  const [credits, setCredits] = useState();
  const [creditsError, setCreditsError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  let [billingInfo, setBillingInfo] = useState({
    name: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
    },
  });

  // this use effect is used for getting the subscription details data and shown in dashboard right corner
  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          setGetUserSubscriptionData(result.data);
        })
      );
    }
  }, []);

  const handleCardTypeChange = (newCardType) => {
    setCardType(newCardType);
  };

  const validateAddress = (address) => {
    let validationErrors = {};

    if (!address.name) validationErrors.name = "Name is required.";
    if (!address.address.line1)
      validationErrors.line1 = "Address line 1 is required.";
    if (!address.address.city) validationErrors.city = "City is required.";
    if (!address.address.postal_code)
      validationErrors.postal_code = "Postal code is required.";
    if (!address.address.country)
      validationErrors.country = "Country is required.";

    return validationErrors;
  };

  const handleAddressChange = (event) => {
    const updatedAddress = event.value;
    setBillingInfo(updatedAddress);
    if (isSubmitted) {
      const validationErrors = validateAddress(updatedAddress);
      setErrors(validationErrors);
    }
  };
  
  const handleCreditsChange = (event) => {
    const { value } = event.target;
    const minLimit = getUserSubscriptionData?.user_credit_min_limit;
    const maxLimit = getUserSubscriptionData?.user_credit_max_limit;

    if (value < minLimit || value > maxLimit) {
        setCreditsError(`Credits must be between ${minLimit} and ${maxLimit}`);
    } else if (value % 1000 !== 0) {
        setCreditsError("Credits must be in multiples of 1000");
    } else {
        setCreditsError("");
    }
    setCredits(value);
};

const handleSubmit = () => {
  setIsSubmitted(true);
  const validationErrors = validateAddress(billingInfo);
  setErrors(validationErrors);

  if (Object.keys(validationErrors).length === 0) {
    // Proceed with payment if no errors
    console.log("Form is valid. Proceeding with payment...");
  }
};


  // Calculate subtotal based on the subscription package
  const pricePerCredit = getUserSubscriptionData.user_per_credit_charge;
  // const subtotal = !isNaN(credits) ? credits * pricePerCredit : 0;
  const subtotal = !isNaN(credits) ? credits * pricePerCredit : 0;

  const totalAmount = !isNaN(subtotal) ? subtotal : 0; // Assuming no additional charges

  return (
    <>
      <Elements stripe={stripePromise}>
        <div className="pageHeadingBlock buildYourICPHeading">
          <div className="pageTitle">
            <h3 className="mb-0">
              <Link to="/subscription-plans" className="backToPage">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M9.13073 15.1663L14.8474 20.8829C15.0807 21.1163 15.1925 21.3885 15.1828 21.6996C15.1731 22.0107 15.0516 22.2829 14.8182 22.5163C14.5849 22.7302 14.3127 22.842 14.0016 22.8517C13.6905 22.8614 13.4182 22.7496 13.1849 22.5163L5.4849 14.8163C5.36823 14.6996 5.28559 14.5732 5.23698 14.4371C5.18837 14.301 5.16406 14.1552 5.16406 13.9996C5.16406 13.8441 5.18837 13.6982 5.23698 13.5621C5.28559 13.426 5.36823 13.2996 5.4849 13.1829L13.1849 5.48294C13.3988 5.26905 13.6661 5.16211 13.987 5.16211C14.3078 5.16211 14.5849 5.26905 14.8182 5.48294C15.0516 5.71628 15.1682 5.99336 15.1682 6.31419C15.1682 6.63503 15.0516 6.91211 14.8182 7.14544L9.13073 12.8329H22.1682C22.4988 12.8329 22.7759 12.9447 22.9995 13.1684C23.2231 13.392 23.3349 13.6691 23.3349 13.9996C23.3349 14.3302 23.2231 14.6072 22.9995 14.8309C22.7759 15.0545 22.4988 15.1663 22.1682 15.1663H9.13073Z"
                    fill="#414141"
                  />
                </svg>
              </Link>{" "}
              Payment Details
            </h3>
          </div>
        </div>
        <div className="pageContentBlock paymentsPage">
          <div className="row">
            <div className="col-12 col-lg-7">
              <div className="billingDetails paymentCard">
                <div className="headingBlock">
                  <h5>Billing Information</h5>
                </div>

                <AddressElement
                  options={{
                    mode: "shipping",
                    defaultValues: billingInfo,
                  }}
                  onChange={handleAddressChange}
                />
                {isSubmitted && Object.keys(errors).length > 0 && (
                  <div className="error-message">
                    {Object.values(errors).map((error, index) => (
                      <p key={index} className="error-message">
                        {error}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div className="cardDetails paymentCard">
                <div className="headingBlock">
                  <h5>Payment Details</h5>
                </div>
                <div className="contentBlock">
                  <Tab.Container id="paymentCards" defaultActiveKey={cardType}>
                    <Nav variant="pills" className="flex-row">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="debit-card"
                          onClick={() => {handleCardTypeChange("debit-card");handleSubmit()}}
                        >
                          <span className="circle"></span> Debit Card
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="credit-card"
                          onClick={() => {handleCardTypeChange("credit-card");handleSubmit()}}
                        >
                          <span className="circle"></span> Credit Card
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey={cardType}>
                        {cardType && Object.keys(errors).length === 0 ? (
                          <CreditPaymentForm
                            cardType={cardType}
                            billingInfo={billingInfo}
                            totalAmount={subtotal} // Passing total amount to CreditPaymentForm
                            minCredits={
                              getUserSubscriptionData?.user_credit_min_limit
                            }
                            maxCredits={
                              getUserSubscriptionData?.user_credit_max_limit
                            }
                            creditsError={creditsError}
                            credits={credits}
                          />
                        ) : null}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                  <br />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="orderSummary paymentCard">
                <div className="headingBlock">
                  <h5>Order Summary</h5>
                </div>
                <div className="contentBlock">
                  <div className="choosedPlan">
                    <div className="planName">
                      {getUserSubscriptionData?.user_package_name}
                    </div>
                    <p>
                      With your {getUserSubscriptionData?.user_package_name}{" "}
                      your credit range is{" "}
                      <strong>
                        {getUserSubscriptionData?.user_credit_min_limit}
                      </strong>{" "}
                      to{" "}
                      <strong>
                        {getUserSubscriptionData?.user_credit_max_limit}
                      </strong>
                    </p>
                  </div>
                  <div className="form-group">
                    <label style={{ marginBottom: "5px" }}>
                      Credits Required
                    </label>
                    <span
                      className="required"
                      style={{ color: "red", marginLeft: "5px" }}
                    >
                      &#42;
                    </span>

                    <div style={{ position: "relative" }}>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Number of credits want to buy"
                        name="credits"
                        value={credits}
                        onChange={handleCreditsChange}
                        style={{
                          width: "100%",
                          padding: "10px",
                          fontSize: "16px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                        }}
                      />
                    </div>

                    {creditsError && (
                      <p
                        className="text-danger"
                        style={{ color: "red", marginTop: "5px" }}
                      >
                        {creditsError}
                      </p>
                    )}
                  </div>

                  <div className="divider"></div>
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="description">
                            {getUserSubscriptionData?.user_package_name}
                          </td>
                          <td className="price">
                            ${!isNaN(subtotal) ? subtotal : 0}
                          </td>
                          <td className="subtotal">${subtotal}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="amountBlock">
                    <ul>
                      {/* <li>
                        Subtotal: <span>${subtotal}</span>
                      </li> */}

                      <li className="totalAmount">
                        Total Amount:
                        <span>${totalAmount}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Elements>
    </>
  );
};

export default CreditPaymentDetails;
