import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./MyProfile.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  getUserSubscriptionPlanDetails,
  spendingHistory,
} from "../../context/actions/User";
import { formatDate } from "../../utils/Validations/ComonFunctions";

ChartJS.register(ArcElement, Tooltip, Legend);

const MyProfile = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);

  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const [getUserData, setGetUserData] = useState([]);
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);
  //get spending history data
  const [getListData, setGetListData] = useState([]);

  //below useEffect for getting user details
  useEffect(() => {
    if (userId) {
      const payload = {
        userId: userId,
      };
      dispatch(
        getUserDetails(payload, token, (result) => {
          setGetUserData(result);
        })
      );
    }
  }, []);
  //below useEffect for getting user subscription details
  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          setGetUserSubscriptionData(result?.data);
        })
      );
    }
  }, []);

  //below useEffect for spending history data
  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        spendingHistory(payload, token, (result) => {
          setGetListData(result);
        })
      );
    }
  }, [dispatch, userId]);
  // Doughnut chart data
  const datacreditspent = {
    datasets: [
      {
        data: [
          getUserSubscriptionData?.credit_spending || 0,
          getUserSubscriptionData?.available_credits || 0,
        ],
        backgroundColor: ["#ECECEC", "#F0675C"],
        hoverBackgroundColor: ["#ECECEC", "#F0675C"],
        borderWidth: 5,
      },
    ],
  };

  // Doughnut chart data
  const datasearchleft = {
    datasets: [
      {
        data: [
          getUserSubscriptionData?.search_credit_assigned || 0,
          getUserSubscriptionData?.searches_left || 0,
        ],
        backgroundColor: ["#ECECEC", "#F0675C"],
        hoverBackgroundColor: ["#ECECEC", "#F0675C"],
        borderWidth: 5,
      },
    ],
  };

  const datadownloads = {
    datasets: [
      {
        data: [
          getUserSubscriptionData?.available_credits || 0,
          getUserSubscriptionData?.user_credit_assigned || 0,
        ],
        backgroundColor: ["#ECECEC", "#F0675C"],
        hoverBackgroundColor: ["#ECECEC", "#F0675C"],
        borderWidth: 5,
      },
    ],
  };

  return (
    <>
      <div className="pageHeadingBlock commonHeadBlock ">
        <div className="pageTitle">
          <h3 className="mb-0">My Profile</h3>
        </div>
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12">
            <div className="contentWrapper myProfilePage sideContentWrapper">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <div className="contentCard profileBLOCK">
                    <div className="userDetails">
                      <div className="userImage text-center">
                        {getUserData.profile_image ? (
                          <img src={getUserData.profile_image} alt="profile" />
                        ) : (
                          <h2
                            className="userNameLetter"
                            style={{
                              background: "$orange3",
                              width: "16rem",
                              height: "16rem",
                              borderRadius: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {getUserData?.first_name
                              ? getUserData.first_name.charAt(0).toUpperCase()
                              : null}
                          </h2>
                        )}
                      </div>
                      <div className="userInfo text-center mb-3 mt-3">
                        <h4>{getUserData?.first_name}</h4>
                        <p className="mb-0">{getUserData?.designation}</p>
                      </div>
                       <div className="socialIcons">
                        <ul>
                          {/* <li>
                            <Link
                              to={
                                getUserData?.facebook_url
                                  ? getUserData?.facebook_url
                                  : ""
                              }
                              target="_blank"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="24"
                                viewBox="0 0 14 24"
                                fill="none"
                              >
                                <path
                                  d="M8.85795 24V13.0533H12.5308L13.0819 8.78588H8.85795V6.06176C8.85795 4.82664 9.19952 3.98492 10.9727 3.98492L13.2305 3.98399V0.167076C12.8401 0.116334 11.4998 0 9.93976 0C6.68225 0 4.45209 1.98836 4.45209 5.63912V8.78588H0.768066V13.0533H4.45209V24H8.85795Z"
                                  fill="#E55426"
                                />
                              </svg>
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              to={
                                getUserData?.linkedin_url
                                  ? getUserData?.linkedin_url
                                  : ""
                              }
                              target="_blank"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_1540_6855)">
                                  <path
                                    d="M23.994 24L24 23.999V15.197C24 10.891 23.073 7.574 18.039 7.574C15.619 7.574 13.995 8.902 13.332 10.161H13.262V7.976H8.489V23.999H13.459V16.065C13.459 13.976 13.855 11.956 16.442 11.956C18.991 11.956 19.029 14.34 19.029 16.199V24H23.994ZM0.396 7.977H5.372V24H0.396V7.977ZM2.882 0C1.291 0 0 1.291 0 2.882C0 4.473 1.291 5.791 2.882 5.791C4.473 5.791 5.764 4.473 5.764 2.882C5.76347 2.11781 5.45966 1.38507 4.9193 0.844703C4.37893 0.304338 3.64619 0.000529769 2.882 0Z"
                                    fill="#E55426"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1540_6855">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              to={
                                getUserData?.instagram_url
                                  ? getUserData?.instagram_url
                                  : ""
                              }
                              target="_blank"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_1540_6860)">
                                  <path
                                    d="M1.50069 1.63231C-0.385312 3.59131 0.00068754 5.67231 0.00068754 11.9943C0.00068754 17.2443 -0.915313 22.5073 3.87869 23.7463C5.37569 24.1313 18.6397 24.1313 20.1347 23.7443C22.1307 23.2293 23.7547 21.6103 23.9767 18.7873C24.0077 18.3933 24.0077 5.60231 23.9757 5.20031C23.7397 2.19331 21.8887 0.460314 19.4497 0.109314C18.8907 0.028314 18.7787 0.00431397 15.9107 -0.000686027C5.73769 0.00431397 3.50769 -0.448686 1.50069 1.63231Z"
                                    fill="#E55426"
                                  />
                                  <path
                                    d="M11.9988 3.13843C8.36779 3.13843 4.91979 2.81543 3.60279 6.19544C3.05879 7.59143 3.13779 9.40444 3.13779 12.0004C3.13779 14.2784 3.06479 16.4194 3.60279 17.8044C4.91679 21.1864 8.39279 20.8624 11.9968 20.8624C15.4738 20.8624 19.0588 21.2244 20.3918 17.8044C20.9368 16.3944 20.8568 14.6084 20.8568 12.0004C20.8568 8.53844 21.0478 6.30343 19.3688 4.62543C17.6688 2.92543 15.3698 3.13843 11.9948 3.13843H11.9988ZM11.2048 4.73544C18.7788 4.72344 19.7428 3.88144 19.2108 15.5784C19.0218 19.7154 15.8718 19.2614 11.9998 19.2614C4.93979 19.2614 4.73679 19.0594 4.73679 11.9964C4.73679 4.85144 5.29679 4.73944 11.2048 4.73344V4.73544ZM16.7288 6.20643C16.4469 6.20643 16.1765 6.31843 15.9771 6.51778C15.7778 6.71713 15.6658 6.98751 15.6658 7.26943C15.6658 7.55136 15.7778 7.82174 15.9771 8.02109C16.1765 8.22044 16.4469 8.33244 16.7288 8.33244C17.0107 8.33244 17.2811 8.22044 17.4804 8.02109C17.6798 7.82174 17.7918 7.55136 17.7918 7.26943C17.7918 6.98751 17.6798 6.71713 17.4804 6.51778C17.2811 6.31843 17.0107 6.20643 16.7288 6.20643ZM11.9988 7.44943C11.4012 7.4495 10.8095 7.56727 10.2574 7.79601C9.70536 8.02476 9.20376 8.36 8.78125 8.7826C7.92796 9.63608 7.44866 10.7936 7.44879 12.0004C7.44892 13.2073 7.92848 14.3647 8.78196 15.218C9.63543 16.0713 10.7929 16.5506 11.9998 16.5504C13.2067 16.5503 14.364 16.0707 15.2173 15.2173C16.0706 14.3638 16.5499 13.2063 16.5498 11.9994C16.5497 10.7926 16.0701 9.63518 15.2166 8.78189C14.3631 7.9286 13.2057 7.4493 11.9988 7.44943ZM11.9988 9.04643C15.9038 9.04643 15.9088 14.9544 11.9988 14.9544C8.09479 14.9544 8.08879 9.04643 11.9988 9.04643Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1540_6860">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              to={
                                getUserData?.twitter_url
                                  ? getUserData?.twitter_url
                                  : ""
                              }
                              target="_blank"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_1540_6865)">
                                  <path
                                    d="M14.2339 10.1624L22.9764 0H20.9047L13.3137 8.82384L7.2507 0H0.257812L9.42618 13.3432L0.257812 24H2.3296L10.3459 14.6817L16.7489 24H23.7418L14.2334 10.1624H14.2339ZM11.3963 13.4608L10.4674 12.1321L3.0761 1.55961H6.25825L12.2231 10.0919L13.1521 11.4206L20.9057 22.5113H17.7235L11.3963 13.4613V13.4608Z"
                                    fill="#E55426"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1540_6865">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div className="contentCard creditsCard">
                    <div className="creditsContent">
                      <div className="availableCredits">
                        <h3>{getUserSubscriptionData?.available_credits}</h3>
                        <p>Available Credits</p>
                      </div>
                      <div className="buyCredits buyCreditBlk">
                        <Link
                          to="/subscription-plans"
                          className="btn btnPrimaryOutline"
                        >
                          Buy Credits
                        </Link>
                      </div>
                    </div>
                    <div className="recordBlock">
                      <div className="recordItem d-flex align-items-center flex-column">
                        <div
                          className="recordGraph"
                          style={{ width: "120px", height: "120px" }}
                        >
                          <Doughnut data={datacreditspent} />
                        </div>
                        <div className="recordCard">
                          <h6>{getUserSubscriptionData?.credit_spending}</h6>
                          <p>credits spent</p>
                        </div>
                      </div>
                      <div className="recordItem d-flex align-items-center flex-column">
                        <div
                          className="recordGraph"
                          style={{ width: "120px", height: "120px" }}
                        >
                          <Doughnut data={datasearchleft} />
                        </div>
                        <div className="recordCard">
                          <h6>
                            {getUserSubscriptionData?.searches_left &&
                              getUserSubscriptionData?.search_credit_assigned && (
                                <>
                                  {getUserSubscriptionData?.searches_left}/
                                  {
                                    getUserSubscriptionData?.search_credit_assigned
                                  }
                                </>
                              )}
                          </h6>

                          <p>search left</p>
                        </div>
                      </div>
                      <div className="recordItem d-flex align-items-center flex-column">
                        <div
                          className="recordGraph"
                          style={{ width: "120px", height: "120px" }}
                        >
                          <Doughnut data={datadownloads} />
                        </div>
                        <div className="recordCard">
                          <h6>
                            {getUserSubscriptionData?.available_credits &&
                              getUserSubscriptionData?.user_credit_assigned && (
                                <>
                                  {getUserSubscriptionData?.available_credits}/
                                  {
                                    getUserSubscriptionData?.user_credit_assigned
                                  }
                                </>
                              )}
                          </h6>

                          <p>Data Downloads</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                          <div className="contentCard">
                <div className="cardHeading">
                  <h5>Current Plan Details</h5>
                </div>
                <div className="cardContent">
                  <ul>
                    <li>
                      Current Plan
                      <span>{getUserSubscriptionData?.user_package_name}</span>
                    </li>
                    <li>
                      Credits Spent
                      <span>{getUserSubscriptionData?.credit_spending}</span>
                    </li>
                    <li>
                      Last Recharge
                      <span>
                        {formatDate(getUserSubscriptionData?.start_date)}
                      </span>
                    </li>
                    <li>
                      Plan Expiry Date
                      <span>
                        {formatDate(getUserSubscriptionData?.end_date)}
                      </span>
                    </li>
                  </ul>
                  <div className="upgrade text-center">
                    <Link
                      to="/subscription-plans"
                      className="btn btnPrimaryOutline"
                    >
                      Upgrade Subscription
                    </Link>
                  </div>
                </div>
              </div>
                </div>
              </div>
              <div className="contentWrapper sideContentWrapper myProfilePage SpaceBLOCk">
              <div className="row">
                <div className="col-12 col-lg-8 d-flex flex-column">
                  <div className="userPersonalDetails flex-grow-1">
                    <div className="headingBlock">
                      <h5>Personal Details</h5>
                    </div>
                    <div className="contentBlock">
                      <div className="row">
                        <div className="col-12 col-md-4 col-sm-6">
                          <div className="form-group">
                            <p>
                              <span>First name</span>
                              {getUserData?.first_name}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-sm-6">
                          <div className="form-group">
                            <p>
                              <span>Last name</span> {getUserData?.last_name}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-sm-6">
                          <div className="form-group">
                            <p>
                              <span>Designation</span>{" "}
                              {getUserData?.designation}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-sm-6">
                          <div className="form-group">
                            <p>
                              <span>Company Name</span>{" "}
                              {getUserData?.company_name}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-sm-6">
                          <div className="form-group">
                            <p>
                              <span>Location</span>
                              {getUserData?.location}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-sm-6">
                          <div className="form-group">
                            <p>
                              <span>Business Email</span> {getUserData?.email}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-sm-6">
                          <div className="form-group">
                            <p>
                              <span>Phone Number</span>{" "}
                              {getUserData?.phone_number}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-sm-6">
                          <div className="form-group">
                            <p>
                              <span>Home Address</span>
                              {getUserData?.home_address}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-sm-6">
                          <div className="form-group">
                            <p>
                              <span>Billing Address</span>{" "}
                              {getUserData?.billing_address}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-sm-6">
                          <div className="form-group">
                            <p>
                              <span>Work Address</span>
                              {getUserData?.work_address}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                <div className="contentCard">
                <div className="cardHeading">
                  <h5>Spending History</h5>
                  <div className="viewAll">
                    <Link
                      to="/spending-history"
                      className="btn btnPrimaryOutline"
                    >
                      View All
                    </Link>
                  </div>
                </div>
                <div className="cardContent table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Task</th>
                        <th>Credit spent</th>
                        <th>Credit Added</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getListData?.map((data) => (
                        <tr key={data.id}>
                          <td>{formatDate(data?.date)}</td>
                          <td>{data?.task === "ICP" ? "VAIS" : data?.task} </td>
                          <td>{data?.credit_spent||0}</td>
                          <td>{data?.credit_added||0}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
                </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-12 col-lg-4">
            <div className="contentWrapper sideContentWrapper myProfilePage">
      
          
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MyProfile;
