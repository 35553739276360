import {
  icpScore,
  userLoginReducer,
  verifyEmailOtpReducer,
  verifyEmailReducer,
  storeTokenAndUid,
  newUserSignUpDetailsReducer,
  prospectDetailsReducer,
  userSubcriptionDataReducer,
  geoLocationReducer,
  IntentRanges
} from "./User";
// import encryptor from "./encryptor";
import storage from "redux-persist/lib/storage";
import persistCombineReducers from "redux-persist/es/persistCombineReducers";
import { LOG_OUT } from "../../utils/constants";
import { loadingReducer } from "./Loading";

const userPersistConfig = {
  key: "front-end-app",
  storage: storage,
  //   transforms: [encryptor],
  blacklist: ["router", "loader"],
};
const rootReducer = persistCombineReducers(userPersistConfig, {
  user: userLoginReducer,
  loading: loadingReducer,
  verifyEmail: verifyEmailReducer,
  icpScoreResult: icpScore,
  verifyEmailOtp: verifyEmailOtpReducer,
  uidAndToken: storeTokenAndUid,
  newUserDetails: newUserSignUpDetailsReducer,
  prospectDetails: prospectDetailsReducer,
  userSubscriptionData: userSubcriptionDataReducer,
  geoLocation:geoLocationReducer,
  IntentRanges:IntentRanges
});

const rootReducerWithLogout = (state, action) => {
  if (action.type === LOG_OUT) {
    // Clear storage on logout
    storage.removeItem("persist:front-end-app");
    sessionStorage.clear();
    localStorage.clear();

    state = undefined;
  }
  return rootReducer(state, action);
};

// Export with the same name
export default rootReducerWithLogout;
