// ToastModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './Toast.scss'; // Import your CSS file for styling
import { Link } from "react-router-dom";
export default function ToastModal({ type, heading, message, isVisible, onClose,is_button ,isCrossbutton=false}) {
  return (
    <Modal show={isVisible} onHide={onClose} className={`ToastModal ${type}`} centered>
    <Modal.Header closeButton> {/* This automatically adds a close button */}
      <Modal.Title>{heading}</Modal.Title>
    </Modal.Header>
  
    <Modal.Body className="modal-body text-center">
    <p dangerouslySetInnerHTML={{ __html: message}} />
      {/* <p>{message}</p> */}
    </Modal.Body>
  
    {/* Remove the line above the footer using custom styles */}
    <Modal.Footer style={{borderTop: 'none'}}>
      {is_button && (
        <div className="form-group m-0 col-12 d-flex justify-content-center">
          <Link to="/subscription-plans" className="mx-3 btn btnPrimaryOutline">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="white" viewBox="0 0 24 24" fill="none">
              <path
                d="M8 19.9998C7.71667 19.9998 7.47917 19.904 7.2875 19.7123C7.09583 19.5206 7 19.2831 7 18.9998C7 18.7165 7.09583 18.479 7.2875 18.2873C7.47917 18.0956 7.71667 17.9998 8 17.9998H16C16.2833 17.9998 16.5208 18.0956 16.7125 18.2873C16.9042 18.479 17 18.7165 17 18.9998C17 19.2831 16.9042 19.5206 16.7125 19.7123C16.5208 19.904 16.2833 19.9998 16 19.9998H8ZM12 15.9998C11.7167 15.9998 11.4792 15.904 11.2875 15.7123C11.0958 15.5206 11 15.2831 11 14.9998V7.8248L9.1 9.6998C8.91667 9.88314 8.6875 9.97897 8.4125 9.9873C8.1375 9.99564 7.9 9.8998 7.7 9.6998C7.51667 9.51647 7.425 9.28314 7.425 8.9998C7.425 8.71647 7.51667 8.48314 7.7 8.2998L11.3 4.6998C11.4 4.5998 11.5083 4.52897 11.625 4.4873C11.7417 4.44564 11.8667 4.4248 12 4.4248C12.1333 4.4248 12.2583 4.44564 12.375 4.4873C12.4917 4.52897 12.6 4.5998 12.7 4.6998L16.3 8.2998C16.4833 8.48314 16.5792 8.7123 16.5875 8.9873C16.5958 9.2623 16.5 9.4998 16.3 9.6998C16.1167 9.88314 15.8833 9.9748 15.6 9.9748C15.3167 9.9748 15.0833 9.88314 14.9 9.6998L13 7.8248V14.9998C13 15.2831 12.9042 15.5206 12.7125 15.7123C12.5208 15.904 12.2833 15.9998 12 15.9998Z"
                fill="#E55426"
              />
            </svg>{" "}
            Upgrade Now
          </Link>
  
          {isCrossbutton && (
            <Button
              onClick={onClose}
              type="submit"
              className="btn"
              style={{ padding: "0rem 6rem" }}
            >
              Cancel
            </Button>
          )}
        </div>
      )}
    </Modal.Footer>
  </Modal>
  
  );
}