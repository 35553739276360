import React, { useEffect, useRef, useState } from "react";
import "./ICPResult.scss";
import "rc-slider/assets/index.css";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import IntentSignalModal from "./IntentSignalModal"; // Import the modal component
import { FirstLetterCapital, intentSignal } from "../../../utils/constants";
import FreeTrialPage from "./FreeTrial/FreeTrialPage";
const ICPResultTable = ({
  columnVisibility,
  selectedRows,
  selectAll,
  handleSelectAll,
  handleCheckboxChange,
  sortedData = [], // Ensure sortedData defaults to an empty array
  handleSort,
  sortBy,
  type,
  currentPage,
  isDownloadedRow
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const tableContainerRef = useRef(null);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const [showScrollBottomButton, setShowScrollBottomButton] = useState(false);
  const pageVisibility = useSelector(
    (state) =>
      state?.userSubscriptionData?.userSubscrptionData?.data

  );
  const IntentRange = useSelector(
    (state) => state?.IntentRanges?.intentRange
  );
  const checkboxRef = useRef(null);

  const handleIntentSignalClick = (data) => {
    setModalData(data);
    setModalShow(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (tableContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          tableContainerRef.current;
        setShowScrollTopButton(scrollTop > 0);
        setShowScrollBottomButton(scrollTop + clientHeight < scrollHeight - 1); // Adjusting by 1 to handle potential rounding issues
      }
    };

    if (tableContainerRef.current) {
      tableContainerRef.current.addEventListener("scroll", handleScroll);
      // Initial check to set the correct state when the component mounts
      handleScroll();
    }

    return () => {
      if (tableContainerRef.current) {
        tableContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToTop = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const scrollToBottom = () => {
    if (tableContainerRef.current) {
      const scrollHeight = tableContainerRef.current.scrollHeight;
      const clientHeight = tableContainerRef.current.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      tableContainerRef.current.scrollTo({
        top: maxScrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleClose = () => {
    setModalShow(false);
  };
  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = selectedRows.length>0 && !selectAll;
    }
  }, [selectAll, selectedRows]);
  return (
    <>
      {pageVisibility?.user_page_visibility >= currentPage ? (
        <div
          className="tableContentBlock table-responsive"
          ref={tableContainerRef}
        >
          <Table>
            <thead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
              }}
            >
              <tr>
                <th>
                  <div className="checkBox checkbox_BLOck">
                    <input
                      ref={checkboxRef}
                      type="checkbox"
                      checked={selectAll}
                     
                      onChange={() => handleSelectAll()}
                    />
                  </div>
                </th>
                {columnVisibility.companyName && (
                  <th onClick={() => handleSort("company_name")}>
                    Company Name
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                )}

                {columnVisibility.icpScore && (
                  <th onClick={() => handleSort("icp_score")}>
                    VAIS Score
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                )}
                {columnVisibility.Topics === true && type === "ICP" && (
                  <th>
                    Intent Signal{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                      onClick={() => handleSort("topics")}
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                )}

                {columnVisibility.website && type === "ABM" && (
                  <th onClick={() => handleSort("website")}>
                    Website
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                )}
                {columnVisibility.domain && type === "ABM" && (
                  <th onClick={() => handleSort("domain")}>
                    Domain
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                )}
                {columnVisibility.mainIndustry && (
                  <th onClick={() => handleSort("industry_id")}>
                    Main Industry
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                )}
                {columnVisibility.sab_industry_name && type === "ICP" && (
                  <th onClick={() => handleSort("sab_industry_name")}>
                    Sub Industry
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                )}
                {columnVisibility.companySize && (
                  <th onClick={() => handleSort("comp_size")}>
                    Company Size
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                )}
                {columnVisibility.revenue === true ? (
                  <th>
                    Revenue
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                      onClick={() => handleSort("revenue")}
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                ) : null}

                {columnVisibility.country === true ? (
                  <th>
                    Country
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                      onClick={() => handleSort("country")}
                    >
                      <path
                        d={
                          sortBy.desc
                            ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                            : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                        }
                        stroke="#667085"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {sortedData.length > 0 ? (
                sortedData?.map((data, index) => {
                  const isDisabled = isDownloadedRow&&isDownloadedRow.includes(data.id); // Check if the row is disabled
                  return (
                    // <tr key={index} aria-disabled={isDisabled}  className={isDisabled ? 'disabled-row' : ''} >
                      <tr key={index}  style={{cursor:'pointer'}} >
                      <td
                        onClick={() => {
                          if (!isDisabled) {
                            handleCheckboxChange(
                              data.id,
                              data.icp_score,
                              data.Topics,
                              data?.replica_id
                            );
                          }
                        }}
                       
                      >
                        {isDisabled?<div className="checkBox" >
                          <input
                            className="myCheckbox"
                            type="checkbox"
                            checked={isDisabled}
                            
                          />
                        </div>:
                        <div className="checkBox">
                        <input
                          type="checkbox"
                          checked={selectedRows.some((row) => row.id === data.id)}
                          disabled={isDisabled} // Disable checkbox if row is disabled
                        />
                      </div>}
                      </td>

                      {columnVisibility.companyName === true ? (
                        <td
                          style={{ whiteSpace: "breakWord", maxWidth: "50px" }}
                          onClick={() => {
                            if (!isDisabled) {
                              handleCheckboxChange(
                                data.id,
                                data.icp_score,
                                data.Topics,
                                data?.replica_id
                              );
                            }
                          }}
                        >
                          {data.company_name}
                        </td>
                      ) : null}

                      {columnVisibility.icpScore === true ? (
                        <td
                          onClick={() => {
                            if (!isDisabled) {
                              handleCheckboxChange(
                                data.id,
                                data.icp_score,
                                data.Topics,
                                data?.replica_id
                              );
                            }
                          }}
                        >
                          <span
                            className={`intent-signal-score ${data.icp_score >= 80
                                ? "super-strong"
                                : data.icp_score >= 60
                                  ? "very-strong"
                                  : data.icp_score >= 55
                                    ? "strong"
                                    : "strong"
                              }`}
                          >
                            <i className="fa fa-circle" aria-hidden="true"></i>
                            {data.icp_score} %
                          </span>
                        </td>
                      ) : null}

                      {columnVisibility.Topics === true && type === "ICP" && (
                        <td
                          className={
                            Array.isArray(data?.Topics) && data?.Topics?.length > 0
                              ? "custom-td"
                              : ""
                          }
                          style={{
                            cursor:
                              Array.isArray(data?.Topics) && data?.Topics?.length > 0
                                ? "pointer"
                                : "default",
                          }}
                          onClick={() => {
                           
                              Array.isArray(data?.Topics) && data?.Topics?.length > 0
                                ? handleIntentSignalClick(data)
                                : handleCheckboxChange(
                                  data.id,
                                  data.icp_score,
                                  data.Topics,
                                  data?.replica_id
                                );
                            
                          }}
                        >
                          {Array.isArray(data?.Topics)
                            ? intentSignal(IntentRange, data?.Topics?.length)
                            : "-"}
                        </td>
                      )}

                      {columnVisibility.website === true && type === "ABM" ? (
                        <td
                          onClick={() => {
                            if (!isDisabled) {
                              handleCheckboxChange(
                                data.id,
                                data.icp_score,
                                data.Topics,
                                data?.replica_id
                              );
                            }
                          }}
                        >
                          {data.website}
                        </td>
                      ) : null}

                      {columnVisibility.domain === true && type === "ABM" ? (
                        <td
                          onClick={() => {
                            if (!isDisabled) {
                              handleCheckboxChange(
                                data.id,
                                data.icp_score,
                                data.Topics,
                                data?.replica_id
                              );
                            }
                          }}
                        >
                          {data.domain}
                        </td>
                      ) : null}

                      {columnVisibility.mainIndustry === true ? (
                        <td
                          onClick={() => {
                            if (!isDisabled) {
                              handleCheckboxChange(
                                data.id,
                                data.icp_score,
                                data.Topics,
                                data?.replica_id
                              );
                            }
                          }}
                          style={{ whiteSpace: "breakWord", maxWidth: "31px" }}
                        >
                          {data.industry_id}
                        </td>
                      ) : null}

                      {columnVisibility.sab_industry_name === true ? (
                        <td
                          onClick={() => {
                            if (!isDisabled) {
                              handleCheckboxChange(
                                data.id,
                                data.icp_score,
                                data.Topics,
                                data?.replica_id
                              );
                            }
                          }}
                          style={{ whiteSpace: "breakWord", maxWidth: "31px" }}
                        >
                          {data.sab_industry_name}
                        </td>
                      ) : null}

                      {columnVisibility.companySize === true ? (
                        <td
                          onClick={() => {
                            if (!isDisabled) {
                              handleCheckboxChange(
                                data.id,
                                data.icp_score,
                                data.Topics,
                                data?.replica_id
                              );
                            }
                          }}
                        >
                          {data.comp_size}
                        </td>
                      ) : null}

                      {columnVisibility.revenue === true ? (
                        <td
                          onClick={() => {
                            if (!isDisabled) {
                              handleCheckboxChange(
                                data.id,
                                data.icp_score,
                                data.Topics,
                                data?.replica_id
                              );
                            }
                          }}
                        >
                          {data.revenue}
                        </td>
                      ) : null}

                      {columnVisibility.country === true ? (
                        <td
                          onClick={() => {
                            if (!isDisabled) {
                              handleCheckboxChange(
                                data.id,
                                data.icp_score,
                                data.Topics,
                                data?.replica_id
                              );
                            }
                          }}
                        >
                          {FirstLetterCapital(data.country)}
                        </td>
                      ) : null}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center" colSpan={12}>
                    No Records Found
                  </td>
                </tr>
              )}

            </tbody>
          </Table>
          {sortedData?.length > 10 && (
            <span>
              {showScrollTopButton && !showScrollBottomButton && (
                <div className={`scroll ${showScrollTopButton ? "show" : ""}`}>
                  <button onClick={scrollToTop} className="btn-scroll">
                    <i class="pi pi-arrow-up"></i>
                  </button>
                </div>
              )}
            </span>
          )}
          {sortedData?.length > 10 && (
            <div className={`scroll ${showScrollBottomButton ? "show" : ""}`}>
              {showScrollBottomButton && showScrollTopButton && (
                <button onClick={scrollToBottom} className="btn-scroll">
                  <i className="pi pi-arrow-down"></i>
                </button>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>

          {pageVisibility.is_free_trial ? <FreeTrialPage type={type} /> :
            <h5>You don't have access to this page.</h5>
          }
        </div>
      )}
      <IntentSignalModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={modalData}
      />
    </>
  );
};

export default ICPResultTable;
