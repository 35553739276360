import React, {useEffect, useState} from 'react'
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux"; 
import { useNavigate } from "react-router-dom";


const SaveSearchFileName = ({ showFileNameModal, onClose, submitSaveFiltersFileNameModal }) => {
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");

  const Validate = (fileName) => {
    if (fileName.length == 0) {
      setError("Please provide a name to save the filters."); // Show error if input is blank
      console.warn("error ",error);
      
    } else {
      setError(""); 
      submitSaveFiltersFileNameModal(fileName)
    }
  };
    return (
        <>
        <Modal className="saveSearchFileNameModal" show={showFileNameModal} onHide={onClose} centered>
            <Modal.Body className="modal-body text-center">
            <h2>Save Searches</h2>
            <p>Provide a meaningful name to save the filters</p>
            <form>
            <div className="row">
              <div className="form-group col-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  name="fileName"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                />
                {/* Show error message if there's an error */}
                {error && <p className="text-danger mt-2">{error}</p>}
              </div>
              <div className="form-group buttonGroup m-0 col-12">
                <Button type="button" className="btn btnPrimaryOutline w-100" onClick={onClose}>Cancel</Button>
                <Button type="button" className="btn w-100" onClick={()=>Validate(fileName)}>Save</Button>
              </div>
            </div>
            </form>
            </Modal.Body>
        </Modal>
        </>
    )
}


export default SaveSearchFileName;