import React from "react";
import Table from "react-bootstrap/Table";
import { formatDateTime } from "../../utils/Validations/ComonFunctions";
import { useNavigate } from "react-router-dom";

const SupportTicketTable = ({
  handleSort,
  sortedData,
  sortBy,
  selectedRows,
  handleCheckboxChange,
}) => {
  const navigate = useNavigate();
  const handleClick = (data) => {
    navigate("/ticket", { state: data });
  };

  return (
    <div className="tableContentBlock table-responsive">
      <Table>
        <thead>
          <tr>
            {/* <th>
              <div className="checkBox">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </div>
            </th> */}
            <th></th>
            <th>
              Ticket Id{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="14"
                viewBox="0 0 16 16"
                fill="none"
                onClick={() => handleSort("ticket_no")}
              >
                <path
                  d={
                    sortBy.desc
                      ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                      : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                  }
                  stroke="#667085"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </th>
            <th>
              Category{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="14"
                viewBox="0 0 16 16"
                fill="none"
                onClick={() => handleSort("issue_category")}
              >
                <path
                  d={
                    sortBy.desc
                      ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                      : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                  }
                  stroke="#667085"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </th>
            <th>Duration </th>
            <th>
              Explaination{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="14"
                viewBox="0 0 16 16"
                fill="none"
                onClick={() => handleSort("explaination")}
              >
                <path
                  d={
                    sortBy.desc
                      ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                      : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                  }
                  stroke="#667085"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </th>
            <th>Priority</th>
            <th>
              Status{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="14"
                viewBox="0 0 16 16"
                fill="none"
                onClick={() => handleSort("status")}
              >
                <path
                  d={
                    sortBy.desc
                      ? "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                      : "M7.99967 3.33203V12.6654M7.99967 12.6654L12.6663 7.9987M7.99967 12.6654L3.33301 7.9987"
                  }
                  stroke="#667085"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.length > 0 ? (
            sortedData?.map((data, index) => (
              <tr key={data.ticket_no}>
                <td>
                  <div className="checkBox">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(data.ticket_id)}
                      onChange={() => handleCheckboxChange(data.ticket_id)}
                    />
                  </div>
                </td>
                <td>
                  <span
                    className="ticketID"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClick(data)}
                  >
                    {data?.ticket_no}
                  </span>
                  <span className="ticketDate">
                    {formatDateTime(data?.date_created)}
                  </span>
                </td>
                <td>
                  {/* <span className="ticketTitle">{data?.issue_category}</span> */}
                  <span className="ticketID">{data?.issue_category}</span>

                  <span className="ticketTitle">
                    {data?.issue_sub_category}
                  </span>
                </td>
                <td>
                  <span className="ticketTitle">{data?.duration}</span>
                </td>
                <td>
                  <span className="explanation">{data?.explaination}</span>
                </td>
                <td>
                  <span className="explanation">{data?.priority}</span>
                </td>
                <td>
                  <span
                    className={`score scoreBLK ${
                      data?.status === "Resolved" ? "high" : "low"
                    }`}
                  >
                    {data?.status}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan={12}>
                No Records Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default SupportTicketTable;
