export const LOG_OUT = "LOG_OUT";
export const REQUEST_LOGIN_SUCCESS = "REQUEST_LOGIN_SUCCESS";
export const USER_EMAIL_SUCCESS = "USER_EMAIL_SUCCESS";
export const LOADER = "LOADER";
export const USER_EMAIL_OTP_SUCCESS = "USER_EMAIL_OTP_SUCCESS";
export const SET_TOKEN_UID = "SET_TOKEN_UID";
export const NEW_USER_SIGNUP_DETAIL = "NEW_USER_SIGNUP_DETAIL";
export const ICP_SCORE_SUCCESS = "ICP_SCORE_SUCCESS";
export const PROSPECT_DETAILS_SUCCESS = "PROSPECT_DETAILS_SUCCESS";
export const USER_SUBSCRIPTION_DATA_SUCCESS = "USER_SUBSCRIPTION_DATA_SUCCESS";
export const GEOLOCATION_SUCCESS ="GEOLOCATION_SUCCESS"
export const INTENT_RANGE_SUCCESS ="INTENT_RANGE_SUCCESS"
// //Local site key
// export const SITE_KEY = "6Lf5-i8pAAAAABIwcvLDzdcOseKBx5BHw9xkVQkE";
//Staging site key
// export const SITE_KEY = "6LcE6TQpAAAAAI9Y8xMFV6iH5GRgPIuQfvc880-Z";
//Production site key

export const SITE_KEY = '6Ld7dygpAAAAACiHzxJ9F5TTdAJl25uxmqHK0IjZ';

export const industryWeightage = [
  { value: "H", label: "High" },
  { value: "M", label: "Medium" },
  { value: "L", label: "Low" },
];

export const revenueWeightage = [
  { value: "H", label: "High" },
  { value: "M", label: "Medium" },
  { value: "L", label: "Low" },
];

export const companySizeWeightage = [
  { value: "H", label: "High" },
  { value: "M", label: "Medium" },
  { value: "L", label: "Low" },
];

export const ITEMS_PER_PAGE = 1000;
export const BASE_URL = "https://sdeiaiml.com:9005";

// export const intentSignal = (number) => {
//   if (number === 1) {
//     return <span className="intent-signal strong custom-td">Strong</span>;
//   } else if (number === 2 || number === 3) {
//     return <span className="intent-signal very-strong custom-td">Very Strong</span>;
//   } else if (number >= 4) {
//     return <span className="intent-signal super-strong custom-td">Super Strong</span>;
//   } else {
//     return "-";
//   }
// };

// export const signalRank = {
//   "Super Strong": 1,
//   "Very Strong": 2,
//   "Strong": 3,
//   "-": 4
// };

export const signalRank = (data, name) => {
  const found = data.find(item => item.name === name);
  return found ? Number(found.from_value) : 0;
};
    




export const FirstLetterCapital = (full_name) => {
  if (!full_name) return ""; // Return an empty string if input is empty

  return full_name
    .split(" ") // Split the full name into individual words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(" "); // Join the words back together
};

export const intentSignal = (ranges,number) => {
  const className={
    Strong:"strong",
    "Very Strong":"very-strong",
    "Super Strong":"super-strong"
  }
  // const ranges = [
  //   {
  //     name: "Strong",
  //     from_value: 1,
  //     to_value: 1,
  //     className: "strong",
  //   },
  //   {
  //     name: "Very Strong",
  //     from_value: 2,
  //     to_value: 3,
  //     className: "very-strong",
  //   },
  //   {
  //     name: "Super Strong",
  //     from_value: 4,
  //     to_value: 40000,
  //     className: "super-strong",
  //   },
  // ];

  // Iterate through the ranges and find the matching one
  for (let i = 0; i < ranges?.length; i++) {
    const { name, from_value, to_value} = ranges[i];
    
   
    if (number >= from_value && number <= to_value) {
      return <span className={`intent-signal ${className[name]} custom-td`}>{name}</span>;
    }
  }

   // Default return if no range matches
};