import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/Validations/ComonFunctions";

const DashboradCreditDetails = ({ getUserSubscriptionData }) => {
  return (
    <div className="contentCard creditsCard CreditCardBLk d-flex justify-content-center flex-column">
      <div className="creditsContent creditsContentblk">
          <div className="row align-items-center m-0">
            <div className="col-md-4 m-0 text-center ps-0">
            <h5 className="mb-1">Available Credits</h5>
            <strong>{getUserSubscriptionData?.available_credits}</strong>
            </div>
            <div className="col-md-4 m-0 d-flex justify-content-center spendingTime">
              <Link to="/spending-history" className="btn btnPrimaryOutline">
                View Spending History
              </Link>
            </div>
            <div className="col-md-4 m-0 d-flex justify-content-center pe-0 spendingTime">
              <Link to="/subscription-plans" className="btn">
                Buy Credits
              </Link>
            </div>
          </div>
      </div>
      <div className="recordBlock RecordTime">
        <div className="row mt-5 m-0">
          <div className="col-sm-4 ps-0 d-flex justify-content-center recordCardMain">
            <div className="recordCard text-center">
            <h6 className="record-head mb-1">
                {getUserSubscriptionData?.searches_left &&
                  getUserSubscriptionData?.search_credit_assigned && (
                    <>
                      {getUserSubscriptionData?.searches_left}/
                      {getUserSubscriptionData?.search_credit_assigned}
                    </>
                  )}
              </h6>
              <p className="report-text m-0">search left</p>
            </div>
          </div>
          <div className="col-sm-4 d-flex justify-content-center recordCardMain">
            <div className="recordCard text-center">
            <h6 className="record-head mb-1"> {formatDate(getUserSubscriptionData?.start_date)}</h6>
              <p className="report-text m-0">last recharge</p>
            </div>
          </div>
          <div className="col-sm-4 pe-0 d-flex justify-content-center recordCardMain">
            <div className="recordCard text-center">
            <h6 className="record-head mb-1">{getUserSubscriptionData?.credit_spending}</h6>
              <p className="report-text mb-0">credits spent</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboradCreditDetails;
