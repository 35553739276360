export const ButtonGroup = ({ handleSubTypeClick, selectedSubType, isDelivery }) => {
  return (
    isDelivery ? <>
    <div className="button-group ButtonYearBlk">
    
      <button
        className={`downloadbtn ${
          selectedSubType === "monthly" ? "highlight" : ""
        }`}
        onClick={() => handleSubTypeClick("monthly")}
      >
        <p className="m-0"><span className="d-block"><b>Monthly</b></span></p>
       
      </button>
      <button
        className={`downloadbtn ${
          selectedSubType === "quarterly" ? "highlight" : ""
        }`}
        onClick={() => handleSubTypeClick("quarterly")}
      >
        <p className="m-0"><span className="d-block"><b>Quarterly</b></span></p>
   
      </button>
    </div>
    </> : <>
    <div className="button-group ButtonYearBlk">
      <button
        className={`downloadbtn ${
          selectedSubType === "job_level" ? "highlight" : ""
        }`}
        onClick={() => handleSubTypeClick("job_level")}
      >
        
        <p className="m-0"><span className="d-block"><b>Job Level</b></span></p>
       {/* <p><strong>EMP Size Wise</strong></p>  */}
      </button>
      <button
        className={`downloadbtn ${
          selectedSubType === "employee_size" ? "highlight" : ""
        }`}
        onClick={() => handleSubTypeClick("employee_size")}
      >
        <p className="m-0"><span className="d-block"><b>Employee Size</b></span></p>
       
      </button>
      <button
        className={`downloadbtn ${
          selectedSubType === "industry" ? "highlight" : ""
        }`}
        onClick={() => handleSubTypeClick("industry")}
      >
        <p className="m-0"><span className="d-block"><b>Main Industry</b></span></p>
   
      </button>
    </div>
    </>
  );
};
