import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import handIcon from "../../assets/images/handIcon.png";
import "./Subscription.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  ContactUsEmail,
  getUserSubscriptionPlanDetails,
  getsubscriptionDetails,
} from "../../context/actions/User";
import { toast } from "react-toastify";
import Select from "react-select";
// Function to format the number in international style (1,000,000)
const formatCurrency = (amount) => {
  return amount
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .replace(/(\d+)(?=(\d{2})(\d{3})+(?!\d))/g, "$1,");
};

const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);

  const userSubscrptionData = useSelector(
    (state) => state?.userSubscriptionData?.userSubscrptionData?.data
  );
  const is_subscription_expired = useSelector(
    (state) => state?.userSubscriptionData?.userSubscrptionData?.is_subscription_expired
  );

  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  //useState for getting all plan information
  const [getSubScriptionDetails, setGetSubScriptionDetails] = useState([]);
  //useState for user's already having plan data
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState({});

  // State variable to track whether subscription details have been fetched
  const [subscriptionDetailsFetched, setSubscriptionDetailsFetched] =
    useState(false);
  const [Duration, setDuration] = useState({ value: '12', label: 'Annually' })
  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result.status === 200) {
            setGetUserSubscriptionData(result?.data);
          } else if (result.status === 404) {
            // navigate("/subscription-plans");
            // toast("Please Buy Subscription Package!", { autoClose: 1200 });
          }
        })
      );
    }
  }, []);

  //below useEffect is for getting all plan information
  useEffect(() => {
    let payalod = {
      duration: Duration.value
    }
    dispatch(
      getsubscriptionDetails(payalod, token, (result) => {
        setGetSubScriptionDetails(result?.data?.plans);
        // Set subscriptionDetailsFetched to true after fetching subscription details
        setSubscriptionDetailsFetched(true);
      })
    );
  }, [Duration]);

  const handleRedirect = (data) => {
    if (getUserSubscriptionData && getUserSubscriptionData.is_free_trial) {
      toast("For switching plans, please connect with the admin", { autoClose: 2000 })
      return
    }
    navigate("/payments", { state: data });
  };
  const buyMoreCredite = () => {
    if (getUserSubscriptionData && getUserSubscriptionData.is_free_trial) {
      toast("To buy more credit, please contact to Admin", { autoClose: 2000 })
      return
    }
    navigate("/credit-payments");
  }
  const mdWidth = Math.floor(12 / getSubScriptionDetails.length);
  const options = [
    { value: '3', label: 'Quarterly' },
    { value: '6', label: 'Half-Yearly' },
    { value: '12', label: 'Annually' }
  ];
  const customStyles = {
    option: (base, { isSelected }) => {
      return {
        ...base,
        color: isSelected ? "#414141" : "#414141",
        backgroundColor: isSelected ? "#fafafa" : "#ffffff",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#fafafa",
        },
        "&:focus": {
          backgroundColor: "#fafafa",
        },
      };
    },

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#ffffff",
      padding: "0",
      border: "none",
      borderBottom: "1px solid #E6E6E6",
      boxShadow: "none",
      borderRadius: "0",
      "&:hover": {
        borderColor: "#E6E6E6",
        backgroundColor: "transparent",
      },
      "&:focus": {
        borderColor: "#E6E6E6",
        backgroundColor: "transparent",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "6px 0",
      maxHeight: "100px",
      overflowY: "auto"
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#414141",
      },
    }),
    Input: (base) => ({
      ...base,
      padding: "0",
      margin: "0",
    }),

    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#414141",
      margin: "0",
    }),
  };

  const handleContactUs = (data) => {
    let paylaod = {
      'subscription_name': data.package_name,
      'username': `${userData.userInfo.first_name} ${userData?.userInfo?.last_name}`,
      'user_email': userData.userInfo.email,
      'duration': data.duration
    }
    dispatch(
      ContactUsEmail(paylaod, token, (res) => {
      })
    )
  }

  return (
    <>
      <div className="pageHeadingBlock commonHeadBlock subscripBlock">
        <div className="pageTitle">
          <h3 className="subscription-pageHeader">
            Select the Ideal Subscription for Your Journey to Success <img src={handIcon} alt="" />
          </h3>
        </div>
        {!userData?.userInfo?.is_staff &&
          getUserSubscriptionData &&
          Object.keys(getUserSubscriptionData).length > 0 && (
            <div className="buyCredits mb-2" >
              <Link style={{ width: "fit-content" }} onClick={() => buyMoreCredite()} className="btn btnPrimaryOutline">
                Buy Additional Credits
              </Link>
            </div>
          )}
      </div>

      <div className="pageContentBlock planSubscritions">
        <div className="form-group col-4 mb-4">
          <label>
            Duration :
          </label>
          <Select
            styles={customStyles}
            isSearchable={false}
            onChange={(e) => { setDuration(e) }}
            options={options}
            value={Duration}
            placeholder="Select"
            className="selectBox1"
            name="Select Duration"
          />
        </div>
        <div className="row">

          {getSubScriptionDetails.map((data) => (
            <div
              key={data.id}
              className={`col-12 col-sm-6 col-md-6 col-lg-${mdWidth}`}
            >
              <div className="planCard">
                <div className="planBillingDetails">
                  <div className="planName text-center">
                    <h3>{data.package_name}</h3>
                  </div>
                  <div className="planPrice">
                    <h2>${formatCurrency(data.price)}</h2>
                  </div>
                </div>
                {/* <div className="plans-btn-block"> */}
                <div className="planFeatures">
                  <p>This Pack Includes:</p>
                  <ul>
                    <li className="yesPlans">
                      <b>{data.data_download_credits || "0"}</b> Data Download Credits
                    </li>
                    {/* <li className="yesPlans">
                      <b>{data.prospect_download_credits || "0"}</b> prospect download credit 
                      </li> */}
                    <li className="yesPlans">
                      <b>{data.number_of_searches || "0"}</b> Number of Searches
                    </li>
                    <li className="yesPlans">
                      Upto <b>{data.page_visibility || "0"}</b> Page Visibility
                    </li>
                    <li className="yesPlans">
                      Additional Credits Bundles Chargeable
                      at <b>${data.additional_credit || "0"}</b> per credit
                    </li>
                    <li className="yesPlans">
                      <b>{data.per_day_data_download_limit || "0"}</b> data download
                      per day
                    </li>
                    <li className='yesPlans'>
                      <b>{data.dedicated_account_manager || 'Email Support'}</b> with Dedicated Account manager
                    </li>
                    <li className="yesPlans"><b>{data.license == 1 ? 'Single License' : data.license + ' Licenses'}</b></li>
                    <li className={data.content_syndication == 'X' ? 'noPlans' : 'yesPlans'}>
                      Content Syndication<span style={{ fontSize: '.8rem' }}> (Single/Multiple Touch)</span><b>{data.content_syndication == 'X' ? '' : ' - ' + data.content_syndication}</b>
                    </li>
                    <li className={data.market_qualified_lead == 'X' ? 'noPlans' : 'yesPlans'}>
                      Market Qualified Lead<span style={{ fontSize: '.8rem' }}> (1 or 2 CQs)</span><b>{data.market_qualified_lead == 'X' ? '' : ' - ' + data.market_qualified_lead}</b>
                    </li>
                    <li className={data.carry_forward_credit == 'X' ? 'noPlans' : 'yesPlans'}>
                      Carry Forward Credit <b>{data.carry_forward_credit != 'X' ? '- ' + data.carry_forward_credit : ''}</b>
                    </li>
                    <li className={data.high_qualified_lead == 'X' ? 'noPlans' : 'yesPlans'}>
                      High Qualified Leads <b>{data.high_qualified_lead != 'X' ? '- ' + data.high_qualified_lead : ''}</b>
                    </li>
                    <li className={data.bant_vpi == 'X' ? 'noPlans' : 'yesPlans'}>
                      BANT+VPI  <b>{data.bant_vpi != 'X' ? '- ' + data.bant_vpi : ''}</b>
                    </li>
                    <li className={data.install_base_program == '2' ? 'noPlans' : 'yesPlans'}>
                      Install Base Program
                    </li>
                    <li className={data.account_profiling == '2' ? 'noPlans' : 'yesPlans'}>
                      Account Profiling
                    </li>
                    <li
                      className={
                        data.buyer_intent_layer === "1" ? "yesPlans" : "noPlans"
                      }
                    >
                      Buyer Intent Layer
                    </li>
                    <li className={data.webinar_ondemand == 'X' ? 'noPlans' : 'yesPlans'}>
                      Webinar/OnDemand <b>{data.webinar_ondemand != 'X' ? ' - ' + data.webinar_ondemand : ''}</b>
                    </li>
                    {/* <li className="yesPlans">
                        Additional Opt-in Leads (B2B Services):{" "}
                        {data.additional_opt_lead || "0"} leads
                      </li>
                      <li className="yesPlans">
                        Additional Leads: {data.additional_lead || "0"} %
                      </li> */}
                    <li className={data.replacement_lead == 'X' ? 'noPlans' : 'yesPlans'}>
                      Replacement Leads <b>{data.replacement_lead != 'X' ? ' - ' + data.replacement_lead : ''}</b>
                    </li>
                    {/* <li className="yesPlans">Over & Above CPL: {data.cpl}</li> */}
                  </ul>
                </div>

                {(data?.is_active_plan || getUserSubscriptionData.user_package_name === data.package_name) ? (
                  <>
                    <div className="currentPlanMessage text-center p-4">
                      <strong style={{ color: "#E55426" }}>
                        {is_subscription_expired ? "This was your previous plan!" : "This is your current plan !"}
                      </strong>
                    </div>

                    {(is_subscription_expired && !data?.is_free_trial || !data?.is_free_trial && userSubscrptionData?.days_left < 8) && (
                      <div className="text-center p-2">
                        {/* <p style={{ color: "#E55426" }}>
                          Plan expires in {userSubscrptionData?.days_left} days
                        </p> */}
                        <button
                          className="getStarted btn"
                          disabled={userData?.userInfo?.is_staff}
                          onClick={() => handleRedirect(data)}
                        >
                          Renew Now!
                        </button>
                      </div>
                    )}
                  </>
                ) : userSubscrptionData?.is_existing_user ? (

                  <button

                    className="getStarted btn"
                    disabled={userData?.userInfo?.is_staff}
                    onClick={() => handleContactUs(data)}
                  >
                    Contact us!
                  </button>
                ) :
                  <button

                    className="getStarted btn"
                    disabled={userData?.userInfo?.is_staff}
                    onClick={() => handleRedirect(data)}
                  >
                    Get Started Now
                  </button>
                }
                {/* </div> */}
                {/* <div className="planFeatures">
                  <p>This Pack Includes:</p>
                  <ul>
                    <li className="yesPlans">
                      {data.data_download_credits || "0"} Data Download Credits
                    </li>
                    <li className="yesPlans">
                      {data.number_of_searches || "0"} Number of Searches
                    </li>
                    <li className="yesPlans">
                      Upto {data.page_visibility || "0"} Page Visibility
                    </li>
                    <li className="yesPlans">
                      Additional Credits at ${data.additional_credit || "0"} per
                      credit
                    </li>
                    <li className="yesPlans">
                      {data.per_day_data_download_limit || "0"} data download
                      per day
                    </li>
                    <li
                      className={
                        data.email_support === "1"
                          ? "yesPlans"
                          : "noPlans"
                      }
                    >
                      Email support with Dedicated Account manager
                    </li>
                    <li className="yesPlans">
                    {data.prospect_download_credits || "0"} prospect download credit 
                    </li>
                    <li className="yesPlans">
                     Carry Forward Credit: {data.carry_forward_credit || "0"} 
                    </li>
                    <li className="yesPlans">{data.license || "0"} License</li>
                    <li
                      className={
                        data.content_syndication === "1"
                          ? "yesPlans"
                          : "noPlans"
                      }
                    >
                      Content Syndication(Single/Multiple Touch)
                    </li>
                    <li
                      className={
                        data.market_qualified_lead === "1"
                          ? "yesPlans"
                          : "noPlans"
                      }
                    >
                      Market Qualified Lead (1 or 2 CQs)
                    </li>
                    <li
                      className={
                        data.high_qualified_lead === "1"
                          ? "yesPlans"
                          : "noPlans"
                      }
                    >
                      High Qualified Leads
                    </li>
                    <li
                      className={data.bant_vpi === "1" ? "yesPlans" : "noPlans"}
                    >
                      BANT+VPI
                    </li>
                    <li
                      className={
                        data.install_base_program === "1"
                          ? "yesPlans"
                          : "noPlans"
                      }
                    >
                      Install Base Program
                    </li>
                    <li
                      className={
                        data.account_profiling === "1" ? "yesPlans" : "noPlans"
                      }
                    >
                      Account Profiling
                    </li>
                    <li
                      className={
                        data.buyer_intent_layer === "1" ? "yesPlans" : "noPlans"
                      }
                    >
                      Buyer Intent Layer
                    </li>
                    <li
                      className={
                        data.webinar_ondemand === "1" ? "yesPlans" : "noPlans"
                      }
                    >
                      Webinar/OnDemand
                    </li>
                    <li className="yesPlans">
                      Additional Opt-in Leads (B2B Services):{" "}
                      {data.additional_opt_lead || "0"} leads
                    </li>
                    <li className="yesPlans">
                      Additional Leads: {data.additional_lead || "0"} %
                    </li>
                    <li
                      className={
                        data.replacement_lead === "1" ? "yesPlans" : "noPlans"
                      }
                    >
                      Replacement Leads
                    </li>
                    <li className="yesPlans">Over & Above CPL: {data.cpl}</li>
                  </ul>
                </div> */}

                {/* {data?.package_name ===
                getUserSubscriptionData?.user_package_name ? (
                  <div className="currentPlanMessage text-center pt-4">
                    <strong style={{ color: "#E55426" }}>
                      This is your current plan !
                    </strong>
                  </div>
                ) : (
                  <button
                    className="getStarted btn"
                    disabled={userData?.userInfo?.is_staff}
                    onClick={() => handleRedirect(data)}
                  >
                    Get Started Now
                  </button>
                )} */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Render the note after subscription details are fetched */}
      {subscriptionDetailsFetched && (
        <div className="orderSummary paymentCard">
          <div className="contentBlock commonHeadBlock">
            <div className="choosedPlan">
              <div className="planName">
                <div className="note">
                  <p>
                    Note:
                    <br />
                    <br />
                    1. The subscriptions are billed annually, half-yearly, or quarterly.
                    <br />2. Replacement leads to be provided within 48 hours
                    from the date of request.
                    <br />3. The response time is 48 hours after the request is raised.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscription;
