import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Link, useNavigate } from "react-router-dom";
import "./MyDownloadList.scss";
import {
  checkDownloadCredits,
  checkProspectDownloadCredits,
  getDownloadFile,
  getDownloadMultipleFile,
  getMyDownloadListData,
  getStandardUserDetails,
  getUserSubscriptionPlanDetails,
} from "../../context/actions/User";
import { useDispatch, useSelector } from "react-redux";
import DownloadTable from "./DownloadTable";
import ICPTitle from "../BuildICP/ICPResult/ICPTitle";
import Information from "../BuildICP/ICPResult/Information";
import DownloadFilters from "./DownloadFilters";
import moment from "moment";
import { toast } from "react-toastify";
import InformationStaff from "../BuildICP/ICPResult/InformationStaff";
import { hostBackURl } from "../../config/http";
import ToastModal from "../../components/Modal/TosterModel";

const MyDownloadList = () => {
  const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const [getListData, setGetListData] = useState([]);
  const [abc, setAbc] = useState([]);

  const [dataToRender, setDataToRender] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const itemPerPage = 10;
  const [validationError, setValidationError] = useState("");
  const isStaff = userData.userInfo.is_staff;
  const [staffDetails, setStaffDetails] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [checkSelectAll, setCheckSelectAll] = useState([]);
  const isSubscribed = useSelector(
    (state) => state?.userSubscriptionData?.userSubscrptionData
  );
  // const udesubscriptiondata = useSelector(
  //   (state) => state?.userSubscriptionData?.userSubscrptionData?.data
  // );
  const [isVisible, setIsVisible] = useState(false);
  const [toastData, setToastData] = useState({});
  // for sorting
  const [sortBy, setSortBy] = useState({
    column: "",
    desc: false,
  });

  //for filters
  const [filters, setFilters] = useState({
    type: [],
    date: {
      startDate: "",
      endDate: "",
    },
  });

  // below useEffect is for getting subscription data
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);

  useEffect(() => {
    if (isSubscribed?.status === 404) {
      if (!isSubscribed.is_subscription_expired) {
        navigate("/subscription-plans");
        toast(isSubscribed.message, { autoClose: 1200 });
      }

    }
  }, [isSubscribed]);

  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result?.status === 200) {
            setGetUserSubscriptionData(result?.data);
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    if (userData.userInfo.is_staff) {
      let id = userData.userInfo?.user_staff?.user;
      dispatch(
        getStandardUserDetails({ user_id: id }, token, (result) => {
          setStaffDetails(result);
        })
      );
    }
  }, []);
  const fetchMyDownloadList = () => {
    const payload = { user_id: userId };
    dispatch(
      getMyDownloadListData(payload, token, (result) => {
        setGetListData(result);
        setAbc(result);
        const total = Math.ceil(Math.max(result?.length, 0) / itemPerPage);
        setTotalPages(total);

        const startIndex = (currentPage - 1) * itemPerPage;
        const endIndex = startIndex + itemPerPage;
        const paginatedData = result?.slice(startIndex, endIndex);
        setDataToRender(paginatedData);
      })
    );
  }
  useEffect(() => {
    fetchMyDownloadList()

  }, [dispatch, userId]);

  //handaling pagination

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      filterData(searchInput, pageNumber);
      // setSelectedRowId(null); // Reset selected row when changing pages
    }
  };

  //for serch input

  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    handlePageChange(1);
    filterData(input, 1);
  };

  // data to be shown with pagination
  const filterData = (input, pageNumber) => {
    const lowercasedInput = input.toLowerCase();

    const filtered = getListData.filter((data) => {
      const filename = data.file_name.toLowerCase();
      const listType = data?.list_type.toLowerCase();

      const shouldExclude =
        lowercasedInput.includes("icp") &&
        listType.includes("icp") &&
        filename !== lowercasedInput;

      const matchesFilename = filename.includes(lowercasedInput);
      const matchesListType = listType.includes(lowercasedInput);
      const isSpecialCondition =
        lowercasedInput.startsWith("v") && listType.includes("icp");

      return (
        (matchesFilename || matchesListType || isSpecialCondition) &&
        !shouldExclude
      );
    });

    const startIndex = (pageNumber - 1) * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    const paginatedData = filtered.slice(startIndex, endIndex);

    setCurrentPage(pageNumber);
    setDataToRender(paginatedData);
  };

  // this is for filtering function
  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => {
      if (filterType === "type") {
        return {
          ...prevFilters,
          [filterType]: Array.isArray(prevFilters[filterType])
            ? prevFilters[filterType].includes(value)
              ? prevFilters[filterType].filter((item) => item !== value)
              : [...prevFilters[filterType], value]
            : value,
          date: {
            startDate: prevFilters.date.startDate,
            endDate: prevFilters.date.endDate,
          },
        };
      } else if (filterType === "startDate" || filterType === "endDate") {
        return {
          ...prevFilters,
          type: [...prevFilters.type],
          date: {
            ...prevFilters.date,
            [filterType]: value,
          },
        };
      } else {
        return prevFilters;
      }
    });
  };

  const handleResetFilters = () => {
    setFilters({
      type: [],
      date: {
        startDate: "",
        endDate: "",
      },
    });

    setSearchInput("");
    setSelectedRows([]);

    // setAbc(getListData);

    setGetListData(abc);

    // Calculate the new total pages based on the original data
    const newTotalPages = Math.ceil(Math.max(abc.length, 0) / itemPerPage);

    // Ensure current page is within the new total pages
    const updatedCurrentPage = Math.min(currentPage, newTotalPages);

    // Calculate the paginated data based on the updated current page and item per page
    const startIndex = updatedCurrentPage
      ? (updatedCurrentPage - 1) * itemPerPage
      : 0;
    const endIndex = startIndex + itemPerPage;
    const paginatedData = abc.slice(startIndex, endIndex);

    // Update states accordingly
    setTotalPages(newTotalPages);
    setCurrentPage(updatedCurrentPage);
    setDataToRender(paginatedData);
  };

  const handleApplyFilters = () => {
    let filtered = abc;
    // Filter by Main Industry

    if (filters.type.length > 0) {
      filtered = filtered.filter((data) =>
        filters.type.includes(data.list_type)
      );
    }
    if (filters.date.startDate && filters.date.endDate) {
      // Convert the start and end dates to moment objects for comparison
      const startDate = moment(filters.date.startDate, "MM:DD:YYYY");
      const endDate = moment(filters.date.endDate, "MM:DD:YYYY");

      filtered = filtered.filter((data) => {
        // Parse fileDate from the format MM:DD:YYYY into a moment object
        const fileDate = moment(data.date, "MM:DD:YYYY");

        // Compare the dates directly
        return fileDate.isBetween(startDate, endDate, null, "[]"); // "[]" includes start and end dates
      });
    }
    // Update total pages based on the filtered data
    const newTotalPages = Math.ceil(filtered.length / itemPerPage);

    // Ensure current page is within the new total pages
    const updatedCurrentPage = Math.min(currentPage, newTotalPages);
    const startIndex = updatedCurrentPage
      ? (updatedCurrentPage - 1) * itemPerPage
      : 1;
    const endIndex = startIndex + itemPerPage;
    const paginatedData = filtered.slice(startIndex, endIndex);
    const page = updatedCurrentPage === 0 ? 1 : updatedCurrentPage;
    const total_page = newTotalPages === 0 ? 1 : newTotalPages
    setGetListData(filtered);
    setCurrentPage(page);
    setTotalPages(total_page);
    setDataToRender(paginatedData);
  };

  //sorting function
  const handleSort = (column) => {
    setSortBy((prevSortBy) => ({
      column,
      desc: column === prevSortBy.column ? !prevSortBy.desc : false,
    }));
  };

  const sortedData = [...dataToRender]?.sort((a, b) => {
    const columnA = a[sortBy.column];
    const columnB = b[sortBy.column];

    if (columnA < columnB) return sortBy.desc ? 1 : -1;
    if (columnA > columnB) return sortBy.desc ? -1 : 1;
    return 0;
  });

  const handleCheckboxChange = (id, type, exfile, is_downloaded) => {
    if (isSubscribed?.is_subscription_expired && !is_downloaded) {
      return
    }
    const isChecked = selectedRows.some((row) => row.id === id);
    const updatedSelectedRows = isChecked
      ? selectedRows.filter((row) => row.id !== id)
      : [...selectedRows, { id, type, exfile }];
    setSelectedRows(updatedSelectedRows);

    if (
      updatedSelectedRows.length === 0 ||
      updatedSelectedRows.length < dataToRender.length
    ) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    let allRows = []
    if (newSelectAll) {
      allRows = dataToRender.map((row) => ({
        id: row.id,
        type: row.list_type,
        exfile: row.exfile,
      }));
      if (isSubscribed?.is_subscription_expired) {
        allRows = dataToRender.filter((ele) => ele.is_downloaded).map((row) => ({
          id: row.id,
          type: row.list_type,
          exfile: row.exfile,
        }));
      }

      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }
  };
  const handleDownload = async (file_id) => {
    dispatch(await getDownloadFile(file_id, token, async (result) => {
      if (result.status == 200) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result.data}`;
        link.download = result?.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetchMyDownloadList()
      } else {
        alert(result?.error)
      }
    }))

  };

  const handleDonwlaodMulitpleFile = async (ids) => {
    dispatch(await getDownloadMultipleFile({ file_ids: ids }, token, async (result) => {
      if (result.status == 200) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result.data}`;
        link.download = result?.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetchMyDownloadList()
        handleUserData();
        setSelectedRows([]);
        setSelectAll(false);
        setIsVisible(false);
      } else {
        setIsVisible(false);
        alert(result?.error)

      }
    }))
  }

  const showInsafficientbalance = (result) => {
    if (result.data.data.insufficient_credits_files.length > 0) {
      const files = result.data.data.insufficient_credits_files.join(', ');
      let data = {
        type: 'errorModal',
        heading: `Insufficient Credits!`,
        message: `You don't have enough credits to download these files:<br><strong>${files}</strong>`
      }

      setToastData(data);
      setIsVisible(true);

    }
  }


  const handleDownloadSelected = () => {
    const ids = selectedRows.map(ele => ele.id);
    let payloadData = {
      ids: ids,
      user_id: userId,
    };

    if (isStaff && userData?.userInfo?.user_staff?.user) {
      payloadData.staff_id = userData.userInfo.user_staff.user;
    }
    show_daily_limit_exide()
    dispatch(checkDownloadCredits(payloadData, token, (result) => {
      console.log("result",)
      if (result?.data?.status === 200) {
        if (result.data.data.ready_to_download_files.length > 0) {
          handleDonwlaodMulitpleFile(result.data.data.ready_to_download_files);
          
        }
        showInsafficientbalance(result)


      }
    }));
    return
    // Create an array of functions to be executed in parallel
    // const downloadPromises = selectedRows.map((selectedRow) => {
    //   let payloadData = {
    //     ids: selectedRow.id,
    //     user_id: userId,
    //   };

    //   if (isStaff && userData?.userInfo?.user_staff?.user) {
    //     payloadData.staff_id = userData.userInfo.user_staff.user;
    //   }

    //   // Define the API function based on the type
    //   const apiFunction = selectedRow.type === "Prospect" ? checkProspectDownloadCredits : checkDownloadCredits;

    //   return new Promise((resolve) => {
    //     dispatch(apiFunction(payloadData, token, (result) => {
    //       if (result?.data?.status === 200) {
    //         if (selectedRow.exfile) {
    //           ids.push(selectedRow.id);
    //         } else {
    //           console.error("Exfile not found for row with id:", selectedRow.id);
    //         }
    //       }
    //       resolve();
    //     }));
    //   });
    // });

    // Wait for all the API calls to finish
    // Promise.all(downloadPromises).then(() => {
    //   // Once all callbacks are resolved, handle the downloaded files

    // });
    // handleDonwlaodMulitpleFile(ids);
    // handleUserData();
    // setSelectedRows([]);
    // setSelectAll(false);
  };

  const handleUserData = async () => {
    if (!isStaff) {
      const payload = {
        user_id: userId,
      };
      await dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result?.status === 200) {
            setGetUserSubscriptionData(result?.data);
          }
        })
      );
    } else {
      if (userData.userInfo.is_staff) {
        let id = userData.userInfo?.user_staff?.user;
        await dispatch(
          getStandardUserDetails({ user_id: id }, token, (result) => {
            setStaffDetails(result);
          })
        );
      }
    }
  };

  const handleindividualDownload = (id, type) => {

    if (isSubscribed?.is_subscription_expired && !type.is_downloaded) {
      // navigate("/subscription-plans");
      // toast("Your plan has expired. Please renew it or purchase a new one.", { autoClose: 1200 });
      return
    }

    // Find the selected row object using the selectedId
    const selectedRow = getListData.find((data) => data.id === id);
    let payloadData = {
      ids: [selectedRow.id],
      user_id: userId,
    };
    if (isStaff) {
      payloadData = {
        ...payloadData,
        staff_id: userData.userInfo?.user_staff?.user,
      };
    }
    if (type.list_type === "Prospect") {
      dispatch(
        checkProspectDownloadCredits(payloadData, token, async (result) => {
          if (result?.data?.status === 200) {
            // Check if the selectedRow is found and has the 'exfile' property
            if (selectedRow && selectedRow.exfile) {
              try {
                handleDownload(selectedRow.id)
              } catch (error) {
                console.error("Error checking file existence:", error);
                alert("There was an error checking the file existence.");
              }
              handleUserData();
              setSelectedRows([]);
            }
          }
        })
      );
    } else {
      dispatch(
        checkDownloadCredits(payloadData, token, async (result) => {

          if (result?.data?.status === 200) {
            if (selectedRow && selectedRow.exfile) {
              try {
                if (result?.data?.data?.insufficient_credits_files.length > 0) {
                  toast("You don't have enough credit to download this file.")
                  return
                }
                handleDownload(selectedRow.id)
              } catch (error) {
                console.error("Error checking file existence:", error);
                alert("There was an error checking the file existence.");
              }
              handleUserData();
              setSelectedRows([]);
            }
          }
        })
      );
    }
  };

  const show_daily_limit_exide = () => {

    let data = {
      type: 'errorModal',
      // heading: 'LAL download limit reached!',
      message: `Your selected report(s) are being downloaded. This may take some time, depending on the size and number of reports. Thank you for your patience!`
    }

    setToastData(data);
    setIsVisible(true);

  }
  return (
    <>
      {isVisible && <ToastModal
        type={toastData.type}
        heading={toastData.heading}
        message={toastData.message}
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
      />}
      <div className="pageHeadingBlock commonHeading  commonHeadBlock">
        {/* <ICPTitle title="My Download List" backTo="/dashboard" /> */}
        <div className="pageTitle">
          <h3 className="m-0">My Download List</h3>
        </div>

        {!isSubscribed?.is_subscription_expired && (
          !isStaff ? (
            <Information getUserSubscriptionData={getUserSubscriptionData} />
          ) : (
            <InformationStaff staffDetails={staffDetails} />
          )
        )}
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper myDownloadList mb-0">
              <div className="tableHeadingBlock mb-0">
                <div className="leftContent">
                  <form className="searchForm me-2 searchBLK">
                    <div className="form-group m-0">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                      />
                      {/* <button type="submit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M14.0625 14.0625L17.8125 17.8125"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.375 15.3125C12.6542 15.3125 15.3125 12.6542 15.3125 9.375C15.3125 6.09581 12.6542 3.4375 9.375 3.4375C6.09581 3.4375 3.4375 6.09581 3.4375 9.375C3.4375 12.6542 6.09581 15.3125 9.375 15.3125Z"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button> */}
                    </div>
                  </form>
                  <div className="filtersBlock">
                    <DownloadFilters
                      filters={filters}
                      handleFilterChange={handleFilterChange}
                      handleResetFilters={handleResetFilters}
                      handleApplyFilters={handleApplyFilters}
                      validationError={validationError}
                      setValidationError={setValidationError}
                      isSubscribed={isSubscribed}
                    />
                    <div className="tableFullScreen">
                      <div
                        className="fullScreenShow d-flex align-items-center"
                        onClick={handle.enter}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M3.33301 7.4987V4.9987C3.33301 4.55667 3.5086 4.13275 3.82116 3.82019C4.13372 3.50763 4.55765 3.33203 4.99967 3.33203H7.49967M16.6663 12.4987V14.9987C16.6663 15.4407 16.4907 15.8646 16.1782 16.1772C15.8656 16.4898 15.4417 16.6654 14.9997 16.6654H12.4997M12.4997 3.33203H14.9997C15.4417 3.33203 15.8656 3.50763 16.1782 3.82019C16.4907 4.13275 16.6663 4.55667 16.6663 4.9987V7.4987M7.49967 16.6654H4.99967C4.55765 16.6654 4.13372 16.4898 3.82116 16.1772C3.5086 15.8646 3.33301 15.4407 3.33301 14.9987V12.4987"
                            stroke="#414141"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                        Full Screen
                      </div>
                    </div>
                    <div className="downloadSelected">
                      <button
                        type="button"
                        className="btn transparentBtn"
                        disabled={selectedRows.length <= 1}
                        onClick={handleDownloadSelected}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M9.99998 12.8429C9.89955 12.8429 9.80607 12.8269 9.71954 12.7948C9.633 12.7628 9.55074 12.7078 9.47275 12.6298L6.88142 10.0385C6.75749 9.91453 6.69633 9.7695 6.69794 9.60338C6.69953 9.43725 6.76069 9.28955 6.88142 9.16027C7.01069 9.03101 7.15919 8.96424 7.32692 8.95996C7.49465 8.95568 7.64316 9.01818 7.77244 9.14746L9.375 10.75V4.375C9.375 4.19765 9.43483 4.04915 9.55448 3.9295C9.67413 3.80983 9.82263 3.75 9.99998 3.75C10.1773 3.75 10.3258 3.80983 10.4455 3.9295C10.5651 4.04915 10.625 4.19765 10.625 4.375V10.75L12.2275 9.14746C12.3515 9.02353 12.4986 8.96236 12.669 8.96396C12.8394 8.96557 12.9893 9.03101 13.1185 9.16027C13.2393 9.28955 13.3018 9.43592 13.306 9.59938C13.3103 9.76283 13.2478 9.90919 13.1185 10.0385L10.5272 12.6298C10.4492 12.7078 10.367 12.7628 10.2804 12.7948C10.1939 12.8269 10.1004 12.8429 9.99998 12.8429ZM5.25642 16.25C4.83547 16.25 4.47917 16.1041 4.1875 15.8125C3.89583 15.5208 3.75 15.1645 3.75 14.7435V13.1089C3.75 12.9316 3.80983 12.7831 3.9295 12.6634C4.04915 12.5438 4.19765 12.484 4.375 12.484C4.55235 12.484 4.70085 12.5438 4.8205 12.6634C4.94015 12.7831 4.99998 12.9316 4.99998 13.1089V14.7435C4.99998 14.8077 5.02669 14.8664 5.0801 14.9199C5.13353 14.9733 5.19231 15 5.25642 15H14.7435C14.8077 15 14.8664 14.9733 14.9199 14.9199C14.9733 14.8664 15 14.8077 15 14.7435V13.1089C15 12.9316 15.0598 12.7831 15.1795 12.6634C15.2991 12.5438 15.4476 12.484 15.625 12.484C15.8023 12.484 15.9508 12.5438 16.0705 12.6634C16.1901 12.7831 16.25 12.9316 16.25 13.1089V14.7435C16.25 15.1645 16.1041 15.5208 15.8125 15.8125C15.5208 16.1041 15.1645 16.25 14.7435 16.25H5.25642Z"
                            fill="#414141"
                          />
                        </svg>{" "}
                        Download
                      </button>
                    </div>
                  </div>
                </div>
                <div className="rightContent">
                  <div className="paginationBlock">
                    <div className="d-flex align-items-center">
                      Page :
                      <div className="dropdown paginationDropdown">
                        <div
                          className="pageDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <input
                            type="text"
                            className="pageNumber"
                            placeholder={currentPage}
                          /> */}
                          <button className="page-drop">{currentPage}</button>
                        </div>
                        <div className="dropdown-menu">
                          <ul>
                            {pageNumbers.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber.toString().padStart(2, "0")}{" "}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      of
                      <span className="totalPages"> {totalPages} </span>
                    </div>
                    <div className="pagination">
                      <div className="prev paginationItem" onClick={() => handlePageChange(currentPage - 1)}>
                        <Link to="" className="paginationLink">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                          // onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <path
                              d="M4.75 9.25C4.65146 9.25046 4.55382 9.23124 4.46281 9.19347C4.37179 9.15569 4.28924 9.10011 4.22 9.03L0.72 5.53C0.57955 5.38937 0.50066 5.19875 0.50066 5C0.50066 4.80125 0.57955 4.61063 0.72 4.47L4.22 1C4.36096 0.908609 4.52852 0.867188 4.69582 0.882376C4.86312 0.897563 5.02048 0.96848 5.14268 1.08376C5.26488 1.19904 5.34483 1.352 5.36973 1.51814C5.39463 1.68428 5.36303 1.85396 5.28 2L2.28 5L5.28 8C5.42045 8.14063 5.49934 8.33125 5.49934 8.53C5.49934 8.72875 5.42045 8.91937 5.28 9.06C5.13527 9.19069 4.9448 9.25897 4.75 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                      <div className="next paginationItem" onClick={() => handlePageChange(currentPage + 1)}>
                        <Link to="" className="paginationLink">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"

                          >
                            <path
                              d="M1.25 9.25C1.14929 9.24661 1.05034 9.22269 0.959199 9.1797C0.868063 9.13671 0.786667 9.07556 0.72 9C0.57955 8.85937 0.50066 8.66875 0.50066 8.47C0.50066 8.27125 0.57955 8.08062 0.72 7.94L3.72 4.94L0.72 1.94C0.660676 1.79599 0.647659 1.63708 0.682755 1.48533C0.717851 1.33359 0.799319 1.19653 0.915851 1.09319C1.03238 0.989854 1.1782 0.925358 1.33305 0.908658C1.4879 0.891957 1.64411 0.923882 1.78 1L5.28 4.5C5.42045 4.64063 5.49934 4.83125 5.49934 5.03C5.49934 5.22875 5.42045 5.41938 5.28 5.56L1.78 9C1.71333 9.07556 1.63194 9.13671 1.5408 9.1797C1.44966 9.22269 1.35071 9.24661 1.25 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FullScreen handle={handle}>
                <div className="tableContentBlock table-responsive mydownload">
                  <DownloadTable
                    getListData={getListData}
                    handleSort={handleSort}
                    sortedData={sortedData}
                    sortBy={sortBy}
                    selectedRows={selectedRows}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    handleSelectAll={handleSelectAll}
                    onCheckboxChange={handleCheckboxChange}
                    handleindividualDownload={handleindividualDownload}
                    isSubscribed={isSubscribed}
                  />
                </div>
              </FullScreen>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyDownloadList;
